import React, { PureComponent } from 'react';
import "./index.scss";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Banner from "../../component/Banner";
import Example from "./component/Example";
const items = [
  {
    image: 'https://imagesize.hrwork.com/sp/files/e9a08e71-76f8-48c7-b48f-c316221ad64f.jpg',
    m_image: "https://imagesize.hrwork.com/sp/files/11961fbc-c931-48fb-9fbb-74add52a428b.jpg",
    title: "为企业提供一站式、系统化的专业人力资源解决方案",
    tips: [],
    hideBtn: true,
    key: 1
  },
]
class ClientExample extends PureComponent {
  componentDidMount () {
    document.title = "客户案例"
  }
  render () {
    return (
      <div className="page-client-example bf">
        <Header activeIndex="2" />
        <Banner items={items} />
        <Example />
        <Footer />
      </div>
    )
  }
}
export default ClientExample;