import React, { PureComponent } from 'react';
import "./index.scss";
import TabV from "../../../../component/TabV";
import commonProblem from "./commonProblem.json";

class Problem extends PureComponent {
  state = {
    translateX: 0
  }
  onTabChange = info => {
    this.setState({
      translateX: info.key * 964
    })
  }
  render () {
    const { translateX } = this.state;

    return (
      <div className="about-problem-box bf bs66 mt40 br6 flex">
        <div className="pt30">
          <TabV onTabChange={this.onTabChange} defaultActiveKey={0} items={[{ "text": "常见问题", "key": 0 }]} />
        </div>

        <div className="problem-content-body oh">
          <div className="problem-tab-wrapper oh">
            <div style={{ transform: `translateX(-${translateX}px)` }} className="problem-tab-content flex">
              <div className="tab-item">
                {
                  commonProblem.map(({ key, ask, answer }) => <div key={key} className="mb30 flex">
                    <div className="mr10"><img width="38" src="https://imagesize.hrwork.com/sp/files/717e3044-552d-40ae-bcae-bf3a8beb8918.png" alt="" /></div>
                    <div className="flex-1">
                      <p className="ask-title cf60">{ask}</p>
                      <p className="fs16 ca7 mt10 ask-anwser">{answer}</p>
                    </div>
                  </div>)
                }
              </div>
              <div className="tab-item">重点问题</div>
              <div className="tab-item">政策问题</div>
              <div className="tab-item">人事问题</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default Problem;