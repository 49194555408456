import React from 'react';
import Title from "../../../../component/Title";
import "./index.scss";
import Bage from "../Bage";

function Item ({ title, tip, icon }) {
  return (
    <div className="character-item tac bf pr">
      <p className="fs30 fwb c4e">{title}</p>
      <p className="fs20 c9 mt10">{tip}</p>
      <div className="pa dot flex ac jc">
        <Bage icon={icon} />
      </div>
    </div>
  )
}
function Character () {
  return (
    <div className="bao-character">
      <Title type="character" titles={["我们的特点"]} />
      <div className="home-character-box w1200 mxauto flex jsb">
        <Item title="快" tip="实时投保  当天生效" icon="https://imagesize.hrwork.com/sp/files/ddd98a57-0b1e-4916-b08a-381185914dff.png" />
        <Item title="省" tip="按天计费  节省成本" icon="https://imagesize.hrwork.com/sp/files/86921845-0fb9-4c8b-bbe6-552d8f4c45e6.png" />
        <Item title="简" tip="流程便捷  效率快速" icon="https://imagesize.hrwork.com/sp/files/7780c6a6-577b-44c5-a511-8f8ef95145db.png" />
      </div>
    </div>
  )
}

export default Character
