import React, { useState, useRef } from 'react';
import './index.scss';
import Title from '../../../../component/Title';
import Switch from '../../../../component/Switch';

const Item = (props) => (
	<div className="item-box tac ib">
		<img width="353" src={props.image} alt="" />
		<p className="c4e fs24 fwb">{props.title}</p>
	</div>
);

const items = [
	{
		image: 'https://imagesize.hrwork.com/sp/files/906eea0c-fbe3-40af-82b4-8f32f11019b5.png',
		title: '2016中国人力资源先锋服务机构',
	},
	{
		image: 'https://imagesize.hrwork.com/sp/files/a5c3e62d-8313-418a-8eb9-50a279ba749d.png',
		title: '2017年度创新创业促进就业优秀企业',
	},
	{
		image: 'https://imagesize.hrwork.com/sp/files/4c5d8422-1db8-4776-b962-271a684600ca.png',
		title: '2017年度人力资源科技最佳创业公司',
	},

	{
		image: 'https://imagesize.hrwork.com/sp/files/749e41ea-fbdc-4bd7-b31c-3813d2de566f.png',
		title: '北京人力资源服务行业学会',
	},
	{
		image: 'https://imagesize.hrwork.com/sp/files/5bdbc578-e831-4858-a21b-c12f1df7f4e6.png',
		title: '大中华区最具潜力人力资源服务机构',
	},
	{
		image: 'https://imagesize.hrwork.com/sp/files/7f81cb41-ce16-4c56-a08a-92d684ecb16d.png',
		title: '高新技术企业证书',
	},
	{
		image: 'https://imagesize.hrwork.com/sp/files/9e0f47dd-a7d4-4d24-a351-adf8a92648d8.png',
		title: '优秀人力资源外包创新服务商',
	},
	{
		image: 'https://imagesize.hrwork.com/sp/files/9378f14c-a196-4225-8bb6-40af375833df.png',
		title: '中国人才交流协会',
	},
	{
		image: 'https://imagesize.hrwork.com/sp/files/6394b03b-30c4-4784-b01d-b85e2b9a7227.png',
		title: '中国人力资源开发研究会人力资本服务分会',
	},
];

function Honor() {
	const isMobile = document.documentElement.className.includes('mobile');

	const [translateX, setTranslateX] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const timer = useRef();

	const onMouseEnter = () => {
		if (!isMobile) {
			timer.current = setInterval(() => {
				setTranslateX((v) => {
					if (v < 2478) {
						return v + 10;
					} else {
						return 0;
					}
				});
			}, 100);
		}
	};

	const onMouseLeave = () => {
		clearInterval(timer.current);
	};

	const pageChange = (page) => {
		if (isMobile) {
			setTranslateX((page - 1) * (1 * 4.97333));
		} else {
			setTranslateX((page - 1) * (3 * 373 + 3 * 40));
		}
		setCurrentPage(page);
	};

	return (
		<div className="honor pt1">
			<Title
				tip="金闪闪服务得到业内广泛认可，多次荣获大奖"
				type="nav-honor"
				titles={['在线招聘与灵活用工服务，我们真诚且靠谱']}
			/>
			<div className="w1200 mxauto mt40 m-honor-main">
				<Switch
					currentPage={currentPage}
					totalPage={isMobile ? 9 : 3}
					pageChange={pageChange}
				>
					<div
						onMouseLeave={onMouseLeave}
						onMouseEnter={onMouseEnter}
						className="w1200 oh m-honor-content"
					>
						<div
							style={{
								transform: `translateX(-${translateX}${
									isMobile ? 'rem' : 'px'
								})`,
							}}
							className="slider-warpper flex jsb"
						>
							{items.map(({ image, title }) => (
								<Item key={image} title={title} image={image} />
							))}
						</div>
					</div>
				</Switch>
			</div>
		</div>
	);
}

export default Honor;
