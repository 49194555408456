import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";
import classnames from "classnames";

function Item (props) {
  return (
    <a target="_blank" href={props.link} className={classnames("item-box pr bsb", { [props.type]: !!props.type })}>
      <div className={classnames("icon", {
        [props.type]: !!props.type
      })}></div>
      <p className="c4e fwb fs30 mt20">{props.title}</p>
      <div className={classnames("more-btn fs20", {
        [props.type]: !!props.type
      })}><span>了解更多</span>
        <svg width="12px" height="20px">
          <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
            <g id="增值服务" transform="translate(-1459.000000, -940.000000)">
              <g id="服务产品" transform="translate(1283.000000, 724.000000)">
                <path d="M183.899495,218.899495 C184.45178,218.899495 184.899495,219.34721 184.899495,219.899495 C184.899495,220.45178 184.45178,220.899495 183.899495,220.899495 L172.898495,220.899495 L172.899495,231.899495 C172.899495,232.45178 172.45178,232.899495 171.899495,232.899495 C171.34721,232.899495 170.899495,232.45178 170.899495,231.899495 L170.899495,219.899495 C170.899495,219.34721 171.34721,218.899495 171.899495,218.899495 L183.899495,218.899495 Z" id="Combined-Shape" transform="translate(177.899495, 225.899495) scale(-1, 1) rotate(-45.000000) translate(-177.899495, -225.899495) "></path>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </a>
  )
}



class ServerDetail extends PureComponent {
  render () {
    return (
      <div className="detail-box bf pt1">
        <Title tip="针对企业、雇员、政府事业单位等推出多款" type="nav-server" titles={["在线招聘与灵活用工服务，我们认真且专业"]} />
        <div className="flex w1200 mxauto jsb mt40 m-detail-box-main">
          <Item type="sass" title="零工经济" link="/hrsass" />
          <Item type="linghuo" title="用工服务" link="/lingGong" />
          <Item type="xiaowei" title="小微社保通服务" link="/xiaowei" />
          <Item type="lingong" title="零工保" link="/lingongBao" />
        </div>
      </div>
    )
  }
}

export default ServerDetail;