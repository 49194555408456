import React, { useEffect } from 'react';
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Search from "./component/Search";
import Content from "./component/Content";


function NewsMore ({ match: { params: { type } } }) {
  useEffect(() => {
    document.title = type || "全部资讯"
  }, [])
  return (
    <>
      <Header />
      <Search type={type} />
      <Content />
      <Footer />
    </>
  )
}



export default NewsMore;