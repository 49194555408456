import React, { PureComponent } from 'react';
import './index.scss';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import Banner from '../../component/Banner';
import Tab from '../../component/Tab';
import AboutDuomi from './component/AboutDuomi';
import Team from './component/Team';
import classnames from 'classnames';
import Concat from './component/Concat';
import Problem from './component/Problem';

const routeTotranslateX = {
	intro: 0,
	team: 1200,
	concat: 2400,
	problem: 3600,
};

const m_routeTotranslateX = {
	intro: 0,
	team: 375 / 37.5,
	concat: (375 * 2) / 37.5,
	problem: (375 * 3) / 37.5,
};

const items = {
	'-1': [
		{
			image: 'https://imagesize.hrwork.com/sp/files/7f56401e-94c0-4131-822a-7f1857c62ce6.jpg',
			m_image:
				'https://imagesize.hrwork.com/sp/files/61d7e5bf-6eda-4d18-873b-5109f47d330f.jpg',
			key: 1,
		},
	],
	'-2': [
		{
			image: 'https://imagesize.hrwork.com/sp/files/7f56401e-94c0-4131-822a-7f1857c62ce6.jpg',
			key: 2,
			m_image:
				'https://imagesize.hrwork.com/sp/files/61d7e5bf-6eda-4d18-873b-5109f47d330f.jpg',
		},
	],
	'-3': [
		{
			image: 'https://imagesize.hrwork.com/sp/files/1170e53a-ae59-4e2f-80de-5aa19408c690.jpg',
			title: '金闪闪以杭州为总部，在全国各地设立了多个分支机构，构建了社会化用工新模式骨干网',
			key: 3,
			m_image:
				'https://imagesize.hrwork.com/sp/files/4bfd8a78-8ec5-41a4-aa74-c17b678f6f48.jpg',
		},
	],
	'-4': [
		{
			image: 'https://imagesize.hrwork.com/sp/files/639d21f8-2f89-428d-9580-4ff9a44af01a.jpg',
			tips: ['用工模式引路者，个人价值释放者'],
			title: '“金闪闪”',
			key: 4,
			m_image:
				'https://imagesize.hrwork.com/sp/files/d2bca29f-68a4-4237-80a2-aa129672239d.jpg',
		},
	],
};

class About extends PureComponent {
	state = {
		translateX: routeTotranslateX[this.props.match.params.tab || 'intro'],
		isMobile: document.documentElement.className.includes('mobile'),
		m_translateX:
			m_routeTotranslateX[this.props.match.params.tab || 'intro'],
	};

	componentDidMount() {
		document.title = '关于我们';
	}

	onTabChange = (info) => {
		this.setState({
			translateX: info.key * 1200,
			m_translateX: (info.key * 375) / 37.5,
		});
	};

	componentWillReceiveProps(newProps) {
		this.setState({
			translateX: routeTotranslateX[newProps.match.params.tab],
			m_translateX: m_routeTotranslateX[newProps.match.params.tab],
		});
	}

	render() {
		const { translateX, isMobile, m_translateX } = this.state;
		const { area } = this.props.match.params;
		return (
			<div className="page-about bf">
				<Header activeIndex="3" />
				<Banner items={items[`-${translateX / 1200 + 1}`]} />
				<div className="tac pr content z11">
					<Tab
						onTabChange={this.onTabChange}
						defaultActiveKey={translateX / 1200}
						items={[
							{ text: '关于金闪闪', key: 0 },
							// { text: '联系金闪闪', key: 2 },
							{ text: '疑难解答', key: 3 },
						]}
					/>
				</div>

				<div className="w1200 mxauto pr content m-content oh">
					<div
						style={{
							transform: `translateX(-${
								isMobile ? m_translateX : translateX
							}${isMobile ? 'rem' : 'px'})`,
						}}
						className="bf tab-content"
					>
						<div
							className={classnames('ib w1200 vt', {
								'h0 oh': translateX !== 0,
							})}
						>
							<AboutDuomi />
						</div>
						<div
							className={classnames('ib w1200 vt', {
								'h0 oh': translateX !== 1200,
							})}
						>
							<Team />
						</div>
						<div
							className={classnames('ib w1200 vt', {
								'h0 oh': translateX !== 2400,
							})}
						>
							<Concat activeArea={area} />
						</div>
						<div
							className={classnames('ib w1200 vt', {
								'h0 oh': translateX !== 3600,
							})}
						>
							<Problem />
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default About;
