import React, { useEffect, useRef, useState } from 'react';
import "./index.scss";
import Header from "../../component/Header";

function NewsSearch (props) {
  const timer = useRef(null);
  const inputEl = useRef(null);

  useEffect(() => {
    document.title = "搜索";
    inputEl.current.focus();
  }, [])

  const onChange = event => {
    event.persist()
    clearTimeout(timer.current);
    timer.current = setTimeout(() => {
      console.log(event.target.value);
    }, 300)
  }

  const back = () => {
    window.history.go(-1)
  }

  return (
    <>
      <Header />
      <div className="news-search-box flex ac jsb">
        <div className="flex-1 search-box m-mr30 flex ac m-pl20 m-pr20">
          <img className="m-mr10" src="https://imagesize.hrwork.com/sp/files/b61e01f3-d713-4cf3-b824-6bce461ec7b5.png" alt="" />
          <input ref={inputEl} placeholder="请输入" autoFocus onChange={onChange} />
        </div>
        <p onClick={back} className="m-fs24 c3 cancel">取消</p>
      </div>
    </>
  )
}

export default NewsSearch

