import React from 'react';
import "./index.scss";
import Header from "../../component/Header";
import Banner from "../../component/Banner";
import Footer from "../../component/Footer";
import Character from "./component/Character";
import Advantage from "./component/Advantage";
import Intro from "./component/Intro";
import Process from "./component/Process";

const items = [
  {
    title: "零工保",
    tips: ["为员工提供更安全的关爱保障"],
    image: 'https://imagesize.hrwork.com/sp/files/cb6bd81b-4dc8-4a03-aa9b-4312de0f1838.png',
    m_image: "https://imagesize.hrwork.com/sp/files/473b3179-a06b-4880-9a5b-23205b06fd41.png",
    key: 1,
  },
  {
    title: "零工保",
    image: 'https://imagesize.hrwork.com/sp/files/5bf4b602-336d-49d0-b108-cfa2aafcacc4.png',
    m_image: "https://imagesize.hrwork.com/sp/files/c28a89fa-3dc2-4153-83d7-42edc31173ac.jpg",
    tips: ["雇主放心   员工安心"],
    key: 2,
  }
]
class LingGongBao extends React.Component {
  render () {
    return (
      <>
        <Header />
        <Banner items={items} />
        <Character />
        <Advantage />
        <Process />
        <Intro />
        <Footer />
      </>
    )
  }
}

export default LingGongBao;