import React, { PureComponent } from 'react';
import './index.scss';
import Title from '../../../../component/Title';
const P = (props) => <p className="c9 fs28 lh60 ti">{props.children}</p>;
const Mark = (props) => (
	<span className="cf60 lh60 fs28 mark">{props.children}</span>
);

class AboutDuomi extends PureComponent {
	render() {
		return (
			<div className="m-about-box">
				<Title type="about-duomi" titles={['金闪闪招聘']} />
				<P>
					金闪闪招聘，专注于在线招聘与短期灵活用工服务，深耕专业技术人才在各行业内的配置与在线人事服务，致力于为超8000多万家企业提供人才服务
				</P>
				<P>
					金闪闪招聘，凭借优质创新的服务模式，先后获得真格基金、火山石投资、元璟资本、知卓资本、聚桌资本、RECRUIT集团、36氪等巨头的多轮投资，业务获得迅猛发展
				</P>
				<P>
					在党和国家宏观人才政策的指引下，基于我国经济“高速增长到高质量发展”的根本转变，人才
					成为经济社会发展的引擎。为能更好地加速行业人才，特别是专业技术人才的增值，金闪闪招聘在互
					联网、大数据、云计算和人工智能技术应用的基础上，构建了线上线下融合的职业生态圈成长模式
					。并且，针对知名企业，尤其是大中型企业，金闪闪不仅提供一站式灵活用工服务，包括人才招聘流
					程外包（RPO）、岗位外包服务，还通过HR
					SAAS管理系统，为企业提供了更有保障的招聘效果
					与更加透明、高效的人事管理服务，以帮助企业从根本上解决找人难、用工隐性成本高等问题
				</P>
				<P>
					金闪闪坚信，未来是一个全链接的世界，我们将积极构建更加高效的人力资源服务系统，实现人
					与社会、人与企业、企业与社会的全面互联，推送社会的和谐进步
				</P>
				<div className="hide gap"></div>
				<Title type="about-duomi" titles={['金闪闪文化']} />
				<p className="c9 fs28 lh60">
					<span className="fwb c0">金闪闪使命</span>&nbsp;{' '}
					<Mark>让社会更高效</Mark>
				</p>
				<p className="c9 fs28 lh60">
					<span className="fwb c0">金闪闪愿景</span>&nbsp;{' '}
					<Mark>轻松招聘，高效榄才</Mark>
				</p>
				<p className="c9 fs28 lh60">
					用轻松、高效、便捷的方式获取人才信息，享受招聘进程全加速，实现招聘效果高掌控。帮助众多
					企业在抢才过程中快人一步，用更短的时间、更低的成本、更精准的方式锁定更合适的人才
				</p>
				<p className="c9 fs28 lh60">
					<span className="fwb c0">价 值 观</span>&nbsp;{' '}
					<Mark>秉持客户第一原则，以需为先，以诚为本</Mark>
				</p>

				<p className="c9 fs28 lh60">
					我们满怀激情服务为每一位客户、对待每一份工作，帮助客户降本增效；
				</p>
				<p className="c9 fs28 lh60">
					我们追求卓越，勇于突破自我，以产品、服务、管理及文化全方位的创新引领行业发展
				</p>
			</div>
		);
	}
}

export default AboutDuomi;
