import classnames from 'classnames'
import env from './env'
import dpr from './dpr'
import rem from './rem'

const ratio = dpr()
const documentElement = document.documentElement

documentElement.className = classnames(document.documentElement.className.trim(), {
    pc: env.isPC,
    mobile: env.isMobile,
    hairline: env.supportHairline,
    [`dpr${ratio}`]: ratio > 1,
})
documentElement.setAttribute('data-dpr', ratio)

const resetRem = () => (document.documentElement.style.fontSize = rem() + "px")

window.addEventListener('resize', resetRem, false)
window.addEventListener('pageshow', e => (e.persisted && resetRem()), false)
resetRem()

window['__DPR__'] = ratio
window['__ENV__'] = env


