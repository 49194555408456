import React, { useState, useEffect } from 'react';
import "./index.scss";
import classnames from "classnames";


function CompanyCard ({ icon, hoverIcon, mini, light }) {
  const [imgSrc, setImgSrc] = useState(icon)
  useEffect(() => {
    if (light) {
      setImgSrc(hoverIcon)
    } else {
      setImgSrc(icon)
    }
  }, [light])
  return (
    <div className={classnames("enterprise-item", {
      mini: !!mini
    })} onMouseOver={() => setImgSrc(hoverIcon)}>
      <img width="100%" src={imgSrc} alt="" />
    </div>
  )
}

export default CompanyCard;


