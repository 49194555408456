import React, { PureComponent } from 'react';
import "./index.scss";
import RcSelect from 'rc-select';
const Option = RcSelect.Option

class Select extends PureComponent {
  render () {
    const { children, width, height, placeholder="请选择" } = this.props
    return (
      <RcSelect placeholder={placeholder} showSearch={false} style={{ width, height }} {...this.props} >
        {children}
      </RcSelect>
    )
  }
}
Select.Option = Option
export default Select;
