import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";

const Item = props => <div className="item bf bs66 br6">
  <p className="flex ac">
    <img src={props.icon} width="40" />
    <span className="c4e fwb fs30 ml20">{props.title}</span>
  </p>
  <p className="c9 fs20 lh40 mt20">{props.tip}</p>
</div>

class HrsassPrice extends PureComponent {
  render() {
    return (
      <div className="hrsass-price-box">
        <Title type="hrsass-price" titles={["给企业带来的价值"]} />
        <div className="flex fw mxauto jsb item-box">
          <Item icon="https://imagesize.hrwork.com/sp/files/46868251-9c28-44a6-9b3b-cd3fd9336892.png" title="提高工作效率" tip="减少线下人工操作，通过系统提升工作效率实现人员成班优化" />
          <Item icon="https://imagesize.hrwork.com/sp/files/c12dfca8-a789-4483-ba23-c3dbd07f2e56.png" title="数据透明化" tip="系统可对员工的工作情况进行记录，掌握员工的真实劳动数据" />
          <Item icon="https://imagesize.hrwork.com/sp/files/32eaf671-a003-4a12-aa4c-fe48afaba5d4.png" title="提高员工满意度" tip="员工可随时查询个人绩效、社保及薪资信息等" />
          <Item icon="https://imagesize.hrwork.com/sp/files/6cd3de3f-5684-439e-b4d4-4b2a9cff0c79.png" title="控制合规风险" tip="员工保险，保障员工合法权益和人身安全" />
        </div>
      </div>
    )
  }
}
export default HrsassPrice;