import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";
import Switch from "../../../../component/Switch";
import ExamplePlay from "../../../../component/Example";
import exzampleData from "../../../../data/exzample.json";


class Example extends PureComponent {
  state = {
    list: exzampleData.slice(0, 3),
    m_list: exzampleData.slice(0, 1),
  }
  pageChange = page => {
    this.setState({
      list: exzampleData.slice(page * 3 - 3, page * 3)
    })
  }
  m_pageChange = page => {
    this.setState({
      m_list: exzampleData.slice(page -1, page)
    })
  }
  render () {
    const { list, m_list } = this.state;
    return (
      <div className="example-box">
        <Title type="hrsass-example" titles={["案例展示"]} />
        <div className="w1200 mxauto pc-example-box-main">
          <Switch pageChange={this.pageChange} totalPage={10}>
            <div className="flex jsb">
              {
                list.map(({ companyName, companyIntro, exzampleIcon, name, id }) => <ExamplePlay companyName={companyName} companyIntro={companyIntro} exzampleIcon={exzampleIcon} key={name} id={id} />)
              }
            </div>
          </Switch>

        </div>



        <div className="m-example-box-main hide m-mt40">
          <Switch pageChange={this.m_pageChange} totalPage={29}>
            <div className="flex jc">
              {
                m_list.map(({ companyName, companyIntro, exzampleIcon, name, id }) => <ExamplePlay companyName={companyName} companyIntro={companyIntro} exzampleIcon={exzampleIcon} key={name} id={id} />)
              }
            </div>
          </Switch>

        </div>
      </div>
    )
  }
}

export default Example;