import React, { PureComponent } from 'react';
import "./index.scss";
import classnames from "classnames";

class TabV extends PureComponent {

  state = {
    activeTabKey: ""
  }

  componentDidMount () {
    const { activeKey, defaultActiveKey } = this.props;
    this.setState({
      activeTabKey: activeKey || defaultActiveKey
    })
  }

  onTabChange = info => {
    this.setState({
      activeTabKey: info.key
    });
    this.props.onTabChange(info);
  }


  m_onTabChange = info => {
    const { activeKey } = this.props;
    if (!activeKey) {
      this.setState({
        activeTabKey: info.key
      });
    }
    this.props.onTabChange(info);
  }


  componentWillReceiveProps(newProps) {
    const { activeKey } = newProps;
    if (activeKey) {
      this.setState({
        activeTabKey: activeKey
      })
    }
  }



  render () {
    const { activeTabKey } = this.state;
    const { items = [], onMouseEnter = () => {}, onMouseLeave = () => {}, activeKey } = this.props;

    return (
      <>
        <div className="tab-v-box br6 bf flex fdc fs24 ccc">
          {
            items.map(item => <div onMouseEnter={() => onMouseEnter(item)} onMouseLeave={() => onMouseLeave(item)} onClick={() => this.onTabChange(item)} key={item.key} className={classnames("cp flex ac jc tabv-item", {
              "active": activeTabKey === item.key
            })}>{item.text}</div>)
          }
        </div>

        <div className="m-tab-box hide">
          {
            items.map(item => <div onClick={() => this.m_onTabChange(item)} className={classnames("m-pr40 shrink-0", {
              "active": activeTabKey === item.key
            })} key={item.key}>{item.text}</div>)
          }
        </div>

      </>
    )
  }
}
export default TabV;