import React from 'react';
import "./index.scss";

function Search ({ type }) {
  return (
    <div className="w1200 mxauto news-more-search br6 bf bs66 mt30 flex jsb ac pl30 pr10">
      <p className="fs28 fwb lh40 c4e m-title">{type || "全部资讯"}</p>
      <div className="flex pc-search">
        <div className="search-box pr flex ac">
          <input className="ml10 input fs20" placeholder="请输入您要搜索的内容…" />
        </div>
        <div className="search-btn fs20 lh28 fwb c4e tac cp">搜索</div>
      </div>

      <a href="/newsSearch">
        <div className="hide m-search">
        <img src="https://imagesize.hrwork.com/sp/files/c8f1b8d4-1e6c-43c3-9ab0-f4b3ef960144.png" alt=""/>
        <p className="m-fs20 fwb c4e">搜索</p>
      </div>
      </a>
    </div>
  )
}

export default Search;