import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";

const Item = props => <div className="hrsass-server-item tac">
  <img width="80" src={props.icon} alt="" />
  <p className="c4e fs30 fwb mt10">{props.title}</p>
  <p className="c9 fs20 tal lh40 mt10">{props.tip}</p>
</div>

class Server extends PureComponent {
  render () {
    return (
      <div className="hrsass-server-box w1200 mxauto bf pt1">
        <Title type="hrsass" titles={["我们的服务"]} />
        <div className="m-hrsass-server-item-box flex fw jsb">
          <Item title="考勤管理" tip="为企业提供智能化考勤、薪资计算、个税申报等服务，帮助企业实现降本增效" icon="https://imagesize.hrwork.com/sp/files/5b5a8a35-0b03-4221-ab55-259d96227a50.png" />
          <Item title="组织架构" tip="以时间轴、平行列等形式，展示公司组织架构，操作简单，方便管理者编制与管理" icon="https://imagesize.hrwork.com/sp/files/764524f2-d157-4af3-80ac-610b589149f1.png" />
          <Item title="人事管理" tip="在员工入职、转正、调岗、离职等各环节流程进行有效管理，并对员工个人历史及合同进行有效管理" icon="https://imagesize.hrwork.com/sp/files/57940e5a-110e-48f5-a25e-f1bcf7916daa.png" />
          <Item title="自助服务" tip="为员工提供通讯录、考勤及各类审批、工资单明细等客户端自助服务" icon="https://imagesize.hrwork.com/sp/files/1b874f6e-a0aa-4221-adeb-21eba170cdf8.png" />
          <Item title="员工管理" tip="完善员工信息，及支持各类绩效考核，方便员工随时查看" icon="https://imagesize.hrwork.com/sp/files/55faee8a-433f-4a01-8d94-4079a1a81a82.png" />
          <Item title="薪资核算" tip="根据在线考勤数据，自动生成工资单，多套薪资方案，支持负责绩效提成" icon="https://imagesize.hrwork.com/sp/files/3ba53d60-382e-4307-8b62-797f665c8618.png" />
          <Item title="社保福利" tip="利用“互联网+社保”模式，社保政策自动同步，灵活用工企业可选择不同投保方案，用户可根据实际情况自动选择投保公司及保险种类" icon="https://imagesize.hrwork.com/sp/files/9b8fa508-daf6-470f-ba80-4b40d6273467.png" />
          <Item title="实名认证" tip="利用“互联网+对工作的人员进行实名认证，保证员工实名制工作" icon="https://imagesize.hrwork.com/sp/files/5af7b86f-397f-4655-ab68-baa0fceb1287.png" />
        </div>
      </div>
    )
  }
}

export default Server;