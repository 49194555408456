import React from 'react';
import "./index.scss";

const Line = () => <span className="p-line"></span>

function H1 (props) {
  return <p className="flex ac h-title mb20">
    <Line />
    <span className="label-title c4e fs24 fwb">{props.title}</span>
  </p>
}

export default H1;