import React, { PureComponent } from 'react';
import "./index.scss";
import Header from "../../component/Header";
import classnames from "classnames";
import Footer from "../../component/Footer";
import BaomingSuccess from "../job/component/BaomingSuccess";
import H1 from "../../component/H1";


const Label = (props) => <span className="yq-label">{props.tip}</span>



const Btn = ({ onClick }) => <div onClick={onClick} className={classnames("btn-box", {
  "baoming": true,
  "concat": false,
})}>联系商家</div>

class JobDetail extends PureComponent {
  state = {
    isShowDialog: false
  }
  baoming = () => {
    this.setState({
      isShowDialog: true
    })
  }
  closeDialog = () => {
    this.setState({
      isShowDialog: false
    })
  }
  render () {
    const { isShowDialog } = this.state;
    return (
      <div className="job-detail-page">
        <Header />
        <div className="page-content">
          <div className="left">
            <div className="job-title boder">
              <div className="name-box">
                <p>
                  <span className="name">便利蜂视频审核</span>
                  <span className="jipin">急聘</span>
                </p>
                <p>
                  <span className="xinzi">5k～8k</span>
                  <span className="name-box-tip">元/月</span>
                  <span className="jie-type">月结</span>
                  <span className="line">|</span>
                  <span className="name-box-tip">审核录入</span>
                  <span className="line">|</span>
                  <span className="name-box-tip">50人</span>
                </p>
              </div>
              <Btn onClick={this.baoming} />
            </div>
            <div className="job-detail boder">
              <H1 title="职位详情" />
              <p className="yaoqiu">
                <Label tip="只招女生" />
                <Label tip="20～48岁" />
              </p>
              <p className="work-content">
                工作内容：1、负责市场信息的收集及反馈；2、负责进行前期的外联、宣传工作；
                3、接待来访的客户； 4、按照所制定的 职位要求： 1、口齿清晰，普通话流利，
                对推广类工作有较高的热情； 2.具备较强的学习能力和优秀的沟通能力； 3.性格
                坚韧，思维敏捷，具备良好的应变能力和承压能力； 4.有敏锐的市场洞察力，有
                强烈的事业心、责任心和积极的工作态度； 5.对自己有较强自信心，有团队荣誉
                感，有明确的目的和野心； 6.性别不限，工作经验不限，有经验优先；
              </p>
              <H1 title="薪资待遇" />
              <p className="yaoqiu">
                <Label tip="包工作餐" />
                <Label tip="交通补助" />
                <Label tip="有提成" />
              </p>
              <p className="mt20">
                <span className="l-v">月结</span>
                <span className="l-v line">|</span>
                <span className="l-v">薪资1500元/月</span>
              </p>
            </div>

            <div className="job-detail boder">
              <H1 title="工作时间" />
              <div className="flex">
                <p className="mr120">
                  <span className="f20 c4e mr10">开始日期</span>
                  <span className="f20 c4e fwb">2019年09月24日</span>
                </p>
                <p>
                  <span className="f20 c4e mr10">结束日期</span>
                  <span className="f20 c4e fwb">2019年09月24日</span>
                </p>
              </div>
              <p className="mt10">
                <Label tip="白班  09:00～18:00" />
              </p>
            </div>
            <div className="job-detail boder">
              <H1 title="工作地点" />
              <p className="c4e fs20">北京站（北京市  东城区）</p>
              <p className="border-dash-line mt20"></p>
              <div className="flex ac jc mt20">
                <Btn onClick={this.baoming} />
              </div>
            </div>
          </div>
          <div className="right bf br6 tac">
            <div className="job-icon d-ib"></div>
            <p className="c4e fs24 fwb mt30 mb40">北京便利蜂零售有限公司</p>
            <p className="tal mt20">
              <span className="c9 fs20 mr10 lh28">行业类型</span>
              <span className="c4e fs20 lh28">零售服务业</span>
            </p>
            <p className="tal mt20">
              <span className="c9 fs20 mr10 lh28">成立时间</span>
              <span className="c4e fs20 lh28">2019年09月06日</span>
            </p>
            <p className="tal mt20">
              <span className="c9 fs20 mr10 lh28">注册资本</span>
              <span className="c4e fs20 lh28">50万人民币</span>
            </p>
            <p className="c4e fs22 find-com">查看该公司全部信息>></p>
          </div>
        </div>
        <Footer />

        <BaomingSuccess title="联系商家" visible={isShowDialog} onClose={this.closeDialog}/>
      </div>
    )
  }
}

export default JobDetail;