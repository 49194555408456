import React from 'react';
import "./index.scss";
import Card from "../Card";
import MiniCard from "../MiniCard";
import Pagination from "../../../../component/Pagination";


function Main () {
  const onPageChange = page => {
    console.log(page)
  }
  return (
    <div className="flex w1200 mxauto mt30 jsb news-main-box">
      <div className="left">
        <Card />
        <Card />
        <Card />
        <Pagination onChange={onPageChange} total={250} />
      </div>
      <div className="right">
        <MiniCard type="top" />
        <MiniCard type="bottom" />
      </div>
    </div>
  )
}

export default Main;