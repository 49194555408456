import React, { PureComponent } from 'react';
import "./index.scss";
import classnames from "classnames";

class Switch extends PureComponent {
  state = {
    currentPage: 1
  }
  next = () => {
    const { pageChange, totalPage } = this.props;
    const { currentPage } = this.state;
    if (currentPage !== totalPage) {
      this.setState({
        currentPage: currentPage + 1
      })
      pageChange(currentPage + 1)
    }
  }
  pre = () => {
    const { pageChange } = this.props;
    const { currentPage } = this.state;
    if (currentPage !== 1) {
      this.setState({
        currentPage: currentPage - 1
      })
      pageChange(currentPage - 1)
    }
  }
  render() {
    const { children, totalPage } = this.props;
    const { currentPage } = this.state;
    return (
      <div className="pr switch-com">
        <div onClick={this.pre} className={classnames("pre page-btn", {
          "disable": currentPage === 1
        })}></div>
        { children }
        <div onClick={this.next} className={classnames("next page-btn", {
          "disable": currentPage === totalPage
        })}></div>
      </div>
    )
  }
}

export default Switch;