import React from 'react';
import Title from "../../../../component/Title";
import "./index.scss";
import H1 from "../../../../component/H1";

function Item ({ icon, title }) {
  return (
    <div className="item flex jc fdc ac tac">
      <div className="dot flex ac jc">
        <img width="46" src={icon} alt="" />
      </div>
      <p className="fs20 c9 mt10">{title}</p>
    </div>
  )
}

function Process () {
  return (
    <div className="bao-process-com">
      <Title type="bao-process" titles={["投保流程"]} />
      <div className="bao-process-main w1200 mxauto flex fdc pl10 pr10 pr pt10">
        <div className="process-box flex-1 bf br6 pt20 pl30 pr30 pb30">
          <H1 title="投保流程" />
          <div className="step flex pt30 ml10">
            <Item title="在线填写投保资料" icon="https://imagesize.hrwork.com/sp/files/6bc4cde5-ee72-4525-97a5-54997608fad8.png" />
            <img className="mt30 mr60 ml60 step-icon" width="30" src="https://imagesize.hrwork.com/sp/files/c1ff02e8-cacd-47f0-929a-4ba3ce1bc77e.png" alt="" />
            <Item title="打印投保单并盖章上传" icon="https://imagesize.hrwork.com/sp/files/784f5683-b890-4d98-8b3a-59df2a2bc55f.png" />
            <img className="mt30 mr60 ml60 step-icon" width="30" src="https://imagesize.hrwork.com/sp/files/c1ff02e8-cacd-47f0-929a-4ba3ce1bc77e.png" alt="" />
            <Item title="线上进行保费支付" icon="https://imagesize.hrwork.com/sp/files/540bf511-6222-455b-8943-7801c018331e.png" />
            <img className="mt30 mr60 ml60 step-icon" width="30" src="https://imagesize.hrwork.com/sp/files/c1ff02e8-cacd-47f0-929a-4ba3ce1bc77e.png" alt="" />
            <Item title="投保完成" icon="https://imagesize.hrwork.com/sp/files/aa9b8776-0315-4f88-be77-c69dfa5dfb33.png" />
          </div>
        </div>

        <div className="process-box flex-1 bf br6 pt20 pl30 pr30 pb30 mt30">
          <H1 title="理赔流程" />
          <div className="step flex pt30 ml10">
            <Item title="出险48小时内线上报案" icon="https://imagesize.hrwork.com/sp/files/2b5efdad-9caf-4955-a758-3970dc6e5f5a.png" />
            <img className="mt30 mr60 ml60 step-icon" width="30" src="https://imagesize.hrwork.com/sp/files/c1ff02e8-cacd-47f0-929a-4ba3ce1bc77e.png" alt="" />
            <Item title="线上理赔申请" icon="https://imagesize.hrwork.com/sp/files/915f5f9a-97e9-4cbf-825a-aaf9ea79c2cb.png" />
            <img className="mt30 mr60 ml60 step-icon" width="30" src="https://imagesize.hrwork.com/sp/files/c1ff02e8-cacd-47f0-929a-4ba3ce1bc77e.png" alt="" />
            <Item title="资料审核" icon="https://imagesize.hrwork.com/sp/files/ecca6a38-a8a4-457d-a0a7-b32481933a89.png" />
            <img className="mt30 mr60 ml60 step-icon" width="30" src="https://imagesize.hrwork.com/sp/files/c1ff02e8-cacd-47f0-929a-4ba3ce1bc77e.png" alt="" />
            <Item title="快速理赔，资料齐全10个工作日内到账" icon="https://imagesize.hrwork.com/sp/files/3e00525a-eeef-4b99-ab20-bb3c29e174e2.png" />
          </div>
        </div>

        <div className="top-f pa br6"></div>
        <div className="bottom-f pa br6"></div>

      </div>
    </div>
  )
}

export default Process;



