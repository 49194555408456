import React, { PureComponent } from 'react';
import "./index.scss";
import Dialog from "../Dialog";

class SubmitDialog extends PureComponent {
  render () {
    const { visible, title, tip, children, onCancel, confirmText = "确定", onConfirm, m_title } = this.props;
    return (
      <Dialog width={516} closable={false} visible={visible}>
        <div className="submit-dialog-com br6">
          <div className="header">
            <div className="fs30 fwb cf">{title}</div>
            <div className="fs20 cf mt10">{tip}</div>
          </div>
          <div onClick={onCancel} className="m-header hide tac m-fs26 fwb pr">{m_title}</div>
          <div className="hide m-tip m-fs24 fwb m-mt30 m-pb10 m-ml30 m-mr30">{tip}</div>
          {children}
          <div className="footer">
            <div onClick={onCancel} className="cancel">取消</div>
            <div onClick={onConfirm} className="done">{confirmText}</div>
          </div>
        </div>
      </Dialog>
    )
  }
}
export default SubmitDialog;
