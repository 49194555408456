import React, { PureComponent } from 'react';
import "./index.scss";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Fixed from "../../component/Fixed";
import Banner from "../../component/Banner";
import Server from "./component/Server";
import Platform from "./component/Platform";
import HrsassPrice from "./component/Price";
import Example from "./component/Example";
const items = [
  {
    title: "人事管理一站式工作平台",
    image: 'https://imagesize.hrwork.com/sp/files/36d49aac-13a7-4d07-a163-8bba29adaec5.jpg',
    m_image: "https://imagesize.hrwork.com/sp/files/03ba0f10-832f-4e2f-992d-5d0d2acffdc7.jpg",
    tips: ["满足全行业、各规模企业人事管理要求"],
    key: 1,
    showBtn: true
  },
  {
    title: "让人事工作简单高效",
    image: 'https://imagesize.hrwork.com/sp/files/e9ae819a-0dfc-442e-8a1e-4841bd6e4935.jpg',
    m_image: "https://imagesize.hrwork.com/sp/files/438600c6-808e-4993-939f-d73e66ce7119.jpg",
    tips: ["兼顾专业性、灵活性及易用性的人事管理平台"],
    key: 2,
    showBtn: true
  }
]
class Hrsass extends PureComponent {
  componentDidMount() {
    document.title = "SASS平台"
  }
  render () {
    return (
      <div className="hrsass-page bf">
        <Fixed showPublish showJob />
        <Header activeIndex="1" />
        <Banner items={items} />
        <Server />
        <Platform />
        <HrsassPrice />
        <Example />
        <Footer />
      </div>
    )
  }
}

export default Hrsass;