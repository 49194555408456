import React, { PureComponent } from 'react';
import './index.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

// const hotCity = ["北京招聘", "上海招聘", "广州招聘", "深圳招聘", "石家庄招聘", "郑州招聘", "济南招聘", "青岛招聘", "合肥招聘", "太原招聘", "西安招聘"]
const hotCity = [
	{
		text: '北京招聘',
		url: '#/job/zhong',
	},
];

const friendLink = [
	{
		text: '后台系统',
		url: 'https://www.vip-ami.cn/mngSite#/welcome',
	},
];
class Fold extends PureComponent {
	state = {
		isOpen: false,
	};
	open = () => {
		const { isOpen } = this.state;
		this.setState({
			isOpen: !isOpen,
		});
	};
	render() {
		const { isOpen } = this.state;
		const { title, tabs, notPage } = this.props;
		return (
			<div className="city-work">
				<p className="title">{title}</p>
				<div className="city-list">
					<p className={classnames({ off: !isOpen })}>
						{tabs.map(({ text, url }) => (
							<a target="_blank" href={url} key={text}>
								{text}
							</a>
						))}
					</p>
					{!notPage && (
						<div
							onClick={this.open}
							className={classnames('action shrink-0', {
								open: !isOpen,
								off: isOpen,
							})}
						></div>
					)}
				</div>
			</div>
		);
	}
}

class Footer extends PureComponent {
	open = () => {};
	render() {
		return (
			<>
				<div className="footer-com">
					{/* <Fold title="热门城市" tabs={hotCity} notPage /> */}
					{/* <Fold title="热门职位" tabs={hotWork} notPage /> */}
					<div className="footer">
						<div className="main">
							<div className="com">
								<img
									width="65"
									src="https://image.vip-ami.cn/sp/files/e7b3e90c-c4e4-4482-8017-2b4a545e2242.png"
									alt=""
								/>

								<p>
									<i>
										<img
											width="19"
											src="https://imagesize.hrwork.com/sp/files/bd6ce174-3cdd-4eb6-8932-cfc852825669.png"
											alt=""
										/>
									</i>
									<span className="info-text">
										山东省临沂市兰山区陶然路与东岳庙街交汇处蓝湾国际A区5号楼一单元901
									</span>
								</p>
								{/* <p>
									<i>
										<img
											width="15"
											src="https://imagesize.hrwork.com/sp/files/0cd389ff-4e69-480e-9a12-53c45927c38a.png"
											alt=""
										/>
									</i>
									<span className="info-text">
										400-8787-282
									</span>
								</p> */}
								<p>
									<i>
										<img
											width="24"
											src="https://imagesize.hrwork.com/sp/files/58970b52-ad43-4027-847b-c373972092b4.png"
											alt=""
										/>
									</i>
									<span className="info-text">
										vip_ami@163.com
									</span>
								</p>
							</div>
							<div className="com-info">
								<p className="p-title">简介</p>
								<p className="p-info-text">
									<Link className="info-text">关于我们</Link>
								</p>
								{/* <p className="p-info-text"><Link target="_blank" to="/about/team" className="info-text">金闪闪团队</Link></p> */}
								{/* <p className="p-info-text">
									<Link
										to="/about/concat"
										className="info-text"
									>
										联系金闪闪
									</Link>
								</p> */}
								<p className="p-info-text">
									<Link className="info-text">疑难解答</Link>
								</p>
							</div>
							<div className="duomi-info">
								<p className="p-title">金闪闪资讯</p>
								{/* <p className="p-info-text"><Link to="/news" className="info-text">金闪闪新闻</Link></p> */}
								{/* <p className="p-info-text"><Link to="/newsMore" className="info-text">工作技巧</Link></p> */}
							</div>
							<div className="help">
								<p className="p-title">帮助中心</p>
								<p className="p-info-text">
									<a
										href="https://www.vip-ami.cn/mngSite"
										target="_blank"
										className="info-text"
									>
										开通服务
									</a>
								</p>
							</div>

							<div className="weixin">
								<div className="ali-report-wrap">
									<a
										className="ace-link ace-link-primary ali-report-link"
										href="https://beian.miit.gov.cn/"
										target="_blank"
										rel="noopener noreferrer"
									>
										<img
											alt=""
											className="report-img"
											src="//img.alicdn.com/tfs/TB1..50QpXXXXX7XpXXXXXXXXXX-40-40.png"
										/>
										<span className="ali-report-link-text">
											鲁ICP备20025841号-1
										</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="m-footer-com hide">
					<Fold title="友情链接" tabs={friendLink} notPage />
					<div className="footer-nav">
						<div className="m-mb20">
							<span className="cf fwb m-fs18 m-mr30">
								公司简介
							</span>
							<a>
								<span className="ccc m-fs18 m-mr20">
									关于我们
								</span>
							</a>
							<a>
								<span className="ccc m-fs18 m-mr20">
									联系金闪闪1
								</span>
							</a>
							<a>
								<span className="ccc m-fs18">疑难解答</span>
							</a>
						</div>
						<div className="m-mb20">
							<span className="cf fwb m-fs18 m-mr30">
								金闪闪资讯
							</span>
						</div>
						<div className="m-mb20">
							<span className="cf fwb m-fs18 m-mr30">
								帮助中心
							</span>
						</div>
					</div>
					<div className="web-info"></div>
				</div>
			</>
		);
	}
}

export default Footer;
