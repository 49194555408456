import React from 'react';
import "./index.scss";
import classnames from "classnames";

class Title extends React.Component {
  render() {
    const { titles, type, tip, } = this.props;
    return (
      <div className="com-title-box">
        <div className={classnames("title", { [type]: true })}>
          {
            titles.map((title, i) => <h2 className="fs40 c4e pr" key={i}>{title}</h2>)
          }
        </div>
        {
          tip && <span className="c9 fs20 mt10 ib tip">{tip}</span>
        }
      </div>
    )
  }
}

export default Title;