import React, { PureComponent } from 'react';
import "./index.scss";
import classnames from "classnames";
import { Link } from 'react-router-dom';


class ExamplePlay extends PureComponent {
  render () {
    const { mb, companyName, companyIntro, exzampleIcon, id, mini } = this.props;
    return (
      <>
        <div className={classnames("example-com", { "mb40": !!mb, "m-mini-none": !!mini })}>
          <img width="100%" src={exzampleIcon} alt="" />
          <div className="card br6 bf mxauto oh">
            <Link target="_blank" to={`/clientDetail/${id}`}>
              <p className="flex jsb ac">
                <span className="fs24 fwb title c4e m-title">{companyName}</span>
                <img className="cp" width="22" src="https://imagesize.hrwork.com/sp/files/78d6a9ec-eb7f-47b6-955b-123a2253f0c5.png" alt="" />
              </p>
              <p className="c9 fs18 lh25 mt10 oh h50 m-text">{companyIntro}</p>
            </Link>
          </div>
        </div>

        <a href={`/clientDetail/${id}`}>
          <div className={classnames("example-com ac hide m-mb30", {
            "m-mini": !!mini
          })}>
            <img src={exzampleIcon} alt="" />
            <div className="bf m-main flex-1 oh">
              <p className="m-fs24 c4e fwb">{companyName}</p>
              <p className="m-mt10 m-fs18 c9 m-p-text">{companyIntro}</p>
            </div>
          </div>
        </a>
      </>
    )
  }
}
export default ExamplePlay;