import React from 'react';
import classnames from 'classnames';
import './index.scss';
import { Link } from 'react-router-dom';
import Login from '../Login';
import { inject } from 'mobx-react';
import M_Nav from './component/m-nav';

@inject((store) => ({
	toggleLoginDialog: store.login.toggleLoginDialog,
}))
class Header extends React.Component {
	state = {
		isShowH5Nav: false,
	};
	showH5Nav = () => {
		this.setState({
			isShowH5Nav: true,
		});
	};
	hideH5Nav = () => {
		this.setState({
			isShowH5Nav: false,
		});
	};
	render() {
		const { activeIndex } = this.props;
		const { isShowH5Nav } = this.state;
		return (
			<>
				<header className="bf header-pc">
					<div className="flex jsb mxauto ac w1200">
						<div className="flex ac">
							<Link to="/">
								<img
									className="mr20"
									width="60"
									src="https://image.vip-ami.cn/sp/files/454f3d0c-54ee-4c74-93d9-5b208e85fa64.png"
									alt=""
								/>
							</Link>
							<div
								className={classnames('pr fs20 c9 mr20 lh80', {
									'active cf60': activeIndex === '0',
								})}
							>
								<Link className="c9 hover-cf60" to="/">
									<span>首页</span>
								</Link>
							</div>
							{/* <div
								className={classnames('pr fs20 c9 mr20 lh80', {
									active: activeIndex === '1',
								})}
							>
								<Link className="c9 hover-cf60" to="/server">
									增值服务
								</Link>
							</div> */}
							{/* <div
								className={classnames('pr fs20 c9 mr20 lh80', {
									active: activeIndex === '2',
								})}
							>
								<Link
									className="c9 hover-cf60"
									to="/clientExample"
								>
									客户案例
								</Link>
							</div> */}
							{/* <div
								className={classnames('pr fs20 c9 mr20 lh80', {
									active: activeIndex === '3',
								})}
							>
								<Link className="c9 hover-cf60" to="/about">
									关于我们
								</Link>
							</div> */}
						</div>
						<div
							onClick={() => {
								window.open('https://www.vip-ami.cn/mngSite');
								// this.props.toggleLoginDialog(true)
							}}
							className="fs20 cf bml login-btn tac lh60 cp"
						>
							注册/登录
						</div>
					</div>
					{/* <Login /> */}
				</header>
				<header className="bf header-h5 flex ac m-pl20 jsb m-pr30">
					<img
						className="header-logo"
						src="https://image.vip-ami.cn/sp/files/454f3d0c-54ee-4c74-93d9-5b208e85fa64.png"
						alt=""
					/>
					{/* <div>
						<span
							onClick={this.showH5Nav}
							className="icon-more-box ib"
						>
							<img
								className="icon-more"
								src="https://imagesize.hrwork.com/sp/files/6c1db678-fd7a-41c9-9be5-0a69fa3fddc7.png"
								alt=""
							/>
						</span>
					</div> */}
				</header>

				<M_Nav visible={isShowH5Nav} cancel={this.hideH5Nav} />
			</>
		);
	}
}

export default Header;
