import React, { PureComponent } from 'react';
import "./index.scss";
import Calendar from 'rc-calendar';
import 'rc-calendar/assets/index.css';
import DatePicker from 'rc-calendar/lib/Picker';
import Select from "../../../../component/Select";
import Message from "../../../../component/Message";
import SubmitDialog from "../../../../component/SubmitDialog";
import Input from "../../../../component/Input";

const Option = Select.Option;



class BaomingDialog extends PureComponent {
  name = null // 姓名
  inputChange = (e) => {
    this.name = e.target.value;
  }
  sex = "男"
  sexChange = (v) => {
    this.sex = v;
  }
  birthday = null
  onDateChange = (value) => {
    this.birthday = value && value.format("YYYY-MM-DD")
  }
  identity = "学生"
  identityChange = (v) => {
    this.identity = v;
  }

  done = () => {
    if (!this.name) {
      Message.error("请输入姓名");
      return;
    }
    if (!this.birthday) {
      Message.error("请选择出生日期")
      return;
    }
    console.log(this.name, this.sex, this.birthday, this.identity);
    Message.success("报名成功")
    this.props.close();
  }
  render () {
    const { visible, close } = this.props;
    const calendar = (<Calendar
      style={{ zIndex: 1001 }}
      showDateInput={false}
      focusablePanel={false}
    />)
    return (
      <SubmitDialog 
        confirmText="完成报名" visible={visible} title="快速报名" tip="注：正规兼职不会收取费用，若收费请提高警惕"
        onConfirm={this.done}
        onCancel={close}
      >
        <div className="job-bm-dialog-box">
          <div className="content">
            <p className="address-title">1、确认工作要求和地点</p>
            <p className="label">工作要求</p>
            <p className="value-dis">只招女生，18～30岁</p>
            <p className="label">工作地点</p>
            <p className="value-dis">中京城-10号楼（吉林  昌邑区）</p>
            <p className="address-title">2、填写资料并验证手机，可更快上岗并确保工资到账</p>
            <div className="item-info">
              <div className="flex-1 item-item">
                <p className="label">真实姓名</p>
                <Input placeholder="请填写您的姓名" onChange={this.inputChange} />
              </div>
              <div className="flex-1">
                <p className="label">您的性别</p>
                <Select onChange={this.sexChange} defaultValue="男" width={220} height={40} >
                  <Option value="男">男</Option>
                  <Option value="女">女</Option>
                </Select>

              </div>
            </div>
            <div className="item-info">
              <div className="flex-1 item-item">
                <p className="label">出生日期</p>
                <DatePicker
                  animation="slide-up"
                  calendar={calendar}
                  style={{ zIndex: 1001 }}
                  onChange={this.onDateChange}
                >
                  {
                    ({ value }) => {
                      return (
                        <div className="input-box">
                          <input
                            placeholder="请选择您的出生日期"
                            readOnly
                            tabIndex="-1"
                            className="ant-calendar-picker-input ant-input"
                            value={value && value.format("YYYY-MM-DD") || ''}
                          />
                          <i aria-label="图标: calendar" className="anticon anticon-calendar ant-calendar-picker-icon"><svg viewBox="64 64 896 896" focusable="false" className="" data-icon="calendar" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M880 184H712v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H384v-64c0-4.4-3.6-8-8-8h-56c-4.4 0-8 3.6-8 8v64H144c-17.7 0-32 14.3-32 32v664c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V216c0-17.7-14.3-32-32-32zm-40 656H184V460h656v380zM184 392V256h128v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h256v48c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8v-48h128v136H184z"></path></svg></i>
                        </div>
                      );
                    }
                  }
                </DatePicker>
              </div>
              <div className="flex-1">
                <p className="label">身份确认</p>
                <Select onChange={this.identityChange} defaultValue="学生" width={220} height={40} >
                  <Option value="学生">学生</Option>
                  <Option value="非学生">非学生</Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </SubmitDialog>
    )
  }
}

export default BaomingDialog;