import React, { PureComponent } from 'react';
import './index.scss';
import Header from '../../component/Header';
import Footer from '../../component/Footer';
import Fixed from '../../component/Fixed';
import Banner from '../../component/Banner';
import Price from './component/Price';
import Server from './component/Server';
import Youshi from './component/Youshi';
import Example from './component/Example';
const items = [
	{
		title: '灵活用工',
		image: 'https://imagesize.hrwork.com/sp/files/76e16b8f-d83d-4bef-987b-0c90cad5e9a1.jpg',
		m_image:
			'https://imagesize.hrwork.com/sp/files/67f599b2-4cbf-433d-bf6b-7eb9d381fd54.jpg',
		tips: ['避繁就简 极致高效'],
		showBtn: true,
		key: 1,
	},
	{
		title: '金闪闪灵活用工',
		image: 'https://imagesize.hrwork.com/sp/files/8e4502c6-d803-466e-8e2b-9ef1fb24915a.jpg',
		m_image:
			'https://imagesize.hrwork.com/sp/files/a5eddea7-106e-465f-bbc7-0cc76ad2b4d8.jpg',
		tips: ['创新、合规合法的灵活用工平台'],
		showBtn: true,
		key: 2,
	},
];
class LingGong extends PureComponent {
	componentDidMount() {
		document.title = '灵活用工服务';
	}
	render() {
		return (
			<div className="bfa">
				<Fixed showPublish showJob />
				<Header activeIndex="1" />
				<Banner items={items} />
				<Price />
				<Server />
				<Youshi />
				<Example />
				<Footer />
			</div>
		);
	}
}

export default LingGong;
