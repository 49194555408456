import React, { PureComponent } from 'react';
import "./index.scss";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import DetailHeader from "./component/DetailHeader";
import DetailContent from "./component/DetailContent";
import exzampleData from "../../data/exzample.json";

class ClientDetail extends PureComponent {
  
  render () {
    const { id } = this.props.match.params;
    const data = exzampleData.find(exzample => exzample.id == id);
    return (
      <div className="bf">
        <Header activeIndex="2" />
        <DetailHeader data={data} />
        <DetailContent data={data} />
        <Footer />
      </div>
    )
  }
}
export default ClientDetail;
