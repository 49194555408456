import React, { useState, memo, useEffect } from 'react';
import Dialog from "../Dialog";
import "./index.scss";
import classnames from "classnames";
import { inject } from "mobx-react";

function LoginTab ({ tabs = [], onTabChange }) {
  const [activeTab, setActiveTab] = useState(tabs[0])
  useEffect(() => {
    setActiveTab(tabs[0])
  }, [tabs])
  const tabChange = info => {
    setActiveTab(info)
    onTabChange(info)
  }
  return (
    <div className="login-tab-wrapper flex pr">
      {
        tabs.map(tab => <p onClick={() => tabChange(tab)} className={classnames("fs22 c9 cp mr30", {
          "active": activeTab.key === tab.key
        })} key={tab.key}>{tab.text}</p>)
      }
    </div>
  )
}

function Input ({ icon, placeholder, type = "text", iconWidth }) {
  return (
    <div className="login-input-box br6 mt20 flex ac">
      <img width={iconWidth} src={icon} alt="" />
      <input className="fs18 c4e ml15" placeholder={placeholder} type={type} />
    </div>
  )
}

const loginTabs = [{ "text": "密码登录", "key": "login-pw" }, { "text": "手机号登录", "key": "login-mobile" }];
const registerTabs = [{ "text": "注册", "key": "register" }];
const resetPwTabs = [{ "text": "忘记密码", "key": "forget-pw" }];

function Login ({ isShowLoginDialog, toggleLoginDialog }) {
  const [type, setType] = useState("login-pw");

  let leftText = "";
  let tabs = []
  switch (type) {
    case "login-pw":
    case "login-mobile":
      leftText = "还没有账号？去注册";
      tabs = loginTabs;
      break;
    case "register":
      tabs = registerTabs;
      leftText = "已有账号？去登录";
      break;
    case "forget-pw":
      tabs = resetPwTabs;
      leftText = "已有账号？去登录";
      break;
    default:
      leftText = "还没有账号？去注册";
      break;
  }

  const leftTextClick = () => {
    setType(type => type.includes("login") ? "register" : "login-pw")
  }

  return (
    <Dialog onClose={() => toggleLoginDialog(false)} width={806} visible={isShowLoginDialog}>
      <div className="flex br6 oh">
        <div className="login-left-box flex ac fdc">
          <img className="mt80" width="228" src="https://imagesize.hrwork.com/sp/files/ccb31055-fb4d-418e-ab08-e422e6f85f48.png" alt="" />
          <p onClick={leftTextClick} className="login-left-change cf fs20 cp mt30">{"<<"}{leftText}</p>
        </div>
        <div className="login-right-box flex-1 pl30 pr30">
          <LoginTab tabs={tabs} onTabChange={info => setType(info.key)} />
          <Input iconWidth="16" icon="https://imagesize.hrwork.com/sp/files/4e549d58-b76c-4c62-9a8c-cf965ff47441.png" placeholder="请输入手机号" />
          {
            type !== "login-mobile" && <Input iconWidth="19" icon="https://imagesize.hrwork.com/sp/files/61467e0f-d2aa-4a1d-b569-d0b22ae28a70.png" placeholder={type === "forget-pw" ? "请输入新密码" : "请输入登录密码"} type="password" />
          }
          {
            type !== "login-pw" && <div className="flex ac">
              <Input iconWidth="24" icon="https://imagesize.hrwork.com/sp/files/6771a7c9-2e32-40b3-aec0-a6367f85ae8c.png" placeholder="请输入短信验证码" />
              <p className="cf fs20 fb br6 bml login-code-btn tac cp ml20 mt20">获取验证码</p>
            </div>
          }
          {
            (type === "login-mobile" || type === "register") && <div className="flex ac mt16">
              <img width="20" src="https://imagesize.hrwork.com/sp/files/50aa8023-1f08-4303-b2e9-a0f6e95d2463.png" alt="" />
              <p className="f16 ml10 lh2">
                <span className="c9">注册登录即代表同意</span>
                <span className="cf60 cp">《用户注册与隐私保护协议》</span>
              </p>
            </div>
          }

          <div className="flex ac jsb mt30 mb35">
            <p className="login-submit-btn cf fs20 fwb bml br6 tac cp">{type === "register" ? "注册" : type === "forget-pw" ? "重置密码" : "登录"}</p>
            {
              type === "login-pw" && <p className="flex ac">
                <img className="mr10" width="20" src="https://imagesize.hrwork.com/sp/files/75e1e68d-c901-4aff-8662-dd19d4c28395.png" alt="" />
                <span onClick={() => setType("forget-pw")} className="c9 fs18 cp">忘记密码？</span>
              </p>
            }
          </div>
        </div>
      </div>
    </Dialog>
  )
}
export default inject(store => ({
  isShowLoginDialog: store.login.isShowLoginDialog,
  toggleLoginDialog: store.login.toggleLoginDialog
}))(memo(Login));


