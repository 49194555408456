import React from 'react';
import './index.scss';
import Header from '../../component/Header';
import Banner from '../../component/Banner';
import Title from '../../component/Title';
import WorkCard from './component/WorkCard';
import Fixed from '../../component/Fixed';
import Server from './component/Server';
import News from './component/News';
import WorkTogether from './component/WorkTogether';
import MiniAppTemplate from './component/MiniAppTemplate';
import Footer from '../../component/Footer';
import Industry from './component/Industry';

const items = [
	// {
	// 	image: 'https://imagesize.hrwork.com/sp/files/396aa173-8206-4388-91bb-c73a3b5afd31.png',
	// 	m_image:
	// 		'https://imagesize.hrwork.com/sp/files/97d91047-ccb6-4f7d-bf3e-3a084838c426.png',
	// 	key: 1,
	// },
	// {
	// 	title: '零工经济服务交易云基地',
	// 	image: 'https://imagesize.hrwork.com/sp/files/85ad0939-342e-4562-a3f5-ee516ea8f8f7.png',
	// 	m_image:
	// 		'https://imagesize.hrwork.com/sp/files/041a04c5-cdc6-4de3-8232-aed590271b5e.png',
	// 	tips: [
	// 		'▪ 人力资源生态的聚合交易平台',
	// 		'▪ 打通人才供应的最后一公里',
	// 		'▪ 为1万万自由职业者解决就业问题',
	// 	],
	// 	key: 2,
	// },
	{
		title: '',
		image: 'https://image.vip-ami.cn/sp/files/236baa3e-dbd6-4e25-8e54-0f5980930914.png',
		m_image:
			'https://imagesize.hrwork.com/sp/files/860a74bc-9796-402b-8fb7-fefd0fdab024.png',
		tips: [''],
		key: 3,
	},
];

class Home extends React.Component {
	componentDidMount() {
		document.title = '金闪闪官网-首页';
	}
	render() {
		return (
			<div className="page-home bf">
				<Header activeIndex="0" />
				<Banner home items={items} />
				<Title
					type="home"
					titles={[
						'专注于提供优质的小程序服务',
						'深耕专业技术人才的信息服务平台',
					]}
				/>
				<div className="work-card">
					<WorkCard
						// tip="Crowdsourcing services"
						title="水果商店小程序"
						type="zhong"
						icon="https://image.vip-ami.cn/sp/files/4016510d-bd76-45ef-8bf7-4b9dbb5b192b.png"
					/>
					<WorkCard
						// tip="Part-time services"
						title="母婴商城小程序"
						type="jian"
						icon="https://image.vip-ami.cn/sp/files/4de464b6-944e-4185-8dfa-b0fff3ae8744.png"
					/>
					<WorkCard
						//	to="/server"
						// tip="Value-added services"
						title="点单小程序"
						type="server"
						icon="https://image.vip-ami.cn/sp/files/28b8cde4-362e-4298-93ce-36c868fb33fa.png"
					/>
					<WorkCard
						// to="http://www.jiesuan.net"
						// tip="Gig economy"
						title="定制小程序"
						type="linggong"
						icon="https://image.vip-ami.cn/sp/files/3b763b19-6809-43de-b4e4-dd627f057207.png"
					/>
				</div>
				<MiniAppTemplate></MiniAppTemplate>
				<Industry />

				{/* <Server />
				<News />
				<WorkTogether /> */}
				<Footer />
				<Fixed />
			</div>
		);
	}
}

export default Home;
