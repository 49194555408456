import React, { useState } from 'react';
import "./index.scss";
import MiniCardTab from "../MiniCardTab";
import MiniCardTabItem from "../MiniCardTabItem";

function MiniCard ({ type }) {
  const onTabChange = info => {

  }
  return (
    <div className="bf br6 bs66 pr news-mini-card-box mb30">
      <MiniCardTab type={type} onTabChange={onTabChange} />
      <MiniCardTabItem />
      <MiniCardTabItem />
      <MiniCardTabItem />
      <MiniCardTabItem />
    </div>
  )
}

export default MiniCard;