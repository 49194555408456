import React, { PureComponent } from 'react';
import './index.scss';
import Title from '../../../../component/Title';
import CompanyCard from '../../../../component/CompanyCard';
import TabV from '../../../../component/TabV';

import cooCompanyType from '../../../../data/cooCompanyType.json';
import cooCompany from '../../../../data/cooCompany.json';

class Follow extends PureComponent {
	state = {
		lightTabId: '',
		m_companyList: cooCompany
			.filter((item) => item.cooTypeId === 1)
			.slice(0, 3),
	};
	onTabChange = (info) => {
		this.setState({
			m_companyList: cooCompany
				.filter((item) => item.cooTypeId === info.id)
				.slice(0, 3),
		});
	};

	onMouseEnter = (info) => {
		this.setState({
			lightTabId: info.id,
		});
	};
	onMouseLeave = (info) => {
		this.setState({
			lightTabId: '',
		});
	};

	render() {
		const { m_companyList } = this.state;

		return (
			<div className="follow-box bf pt1">
				<Title
					width={1013}
					tip="金闪闪服务得到业内广泛认可，多次荣获大奖"
					type="follow"
					titles={['因为我们的专业和匠心，所以才能吸引他们的关注']}
				/>

				<div className="pc-follow-box-main">
					<div className="mt40 bfa w1200 mxauto p10 br6 flex bsee">
						<TabV
							onTabChange={this.onTabChange}
							onMouseEnter={this.onMouseEnter}
							onMouseLeave={this.onMouseLeave}
							defaultActiveKey={0}
							items={cooCompanyType}
						/>

						<div className="server-follow-tab-wrapper oh flex fw jsb">
							{cooCompanyType.map(({ id, name }) => (
								<div
									className="line-box flex jsa"
									title={name}
									key={id}
								>
									{cooCompany
										.filter(
											(company) =>
												company.cooTypeId === id &&
												!company.isHide
										)
										.map(({ name, hoverIcon, icon }) => (
											<div
												style={{ marginRight: '4px' }}
												key={name}
												className="ib"
											>
												<CompanyCard
													light
													mini
													icon={icon}
													hoverIcon={hoverIcon}
												/>
											</div>
										))}
								</div>
							))}
						</div>
					</div>
				</div>

				<div className="m-honor-main hide m-pl30 m-pr30 m-mt40 m-pb20">
					<TabV
						onTabChange={this.onTabChange}
						defaultActiveKey={0}
						items={cooCompanyType}
					/>
					<div className="flex fw jsb m-mt20">
						{m_companyList.map(({ name, hoverIcon, icon }) => (
							<div key={name} className="ib">
								<CompanyCard
									light
									icon={icon}
									hoverIcon={hoverIcon}
								/>
							</div>
						))}
						<i className="m-com-gap"></i>
						<i className="m-com-gap"></i>
					</div>
				</div>
			</div>
		);
	}
}
export default Follow;
