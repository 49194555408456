import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from "mobx-react";
import stores from "./store";

import Home from "./pages/home";
import Job from "./pages/job";
import JobDetail from "./pages/jobDetail";
import Server from "./pages/server";
import Hrsass from "./pages/hrsass";
import LingGong from "./pages/lingGong";
import Xiaowei from "./pages/xiaowei";
import ClientExample from "./pages/clientExample";
import ClientDetail from "./pages/clientDetail";
import About from "./pages/about";
import News from "./pages/news";
import NewsMore from "./pages/newsMore";
import NewsDetail from "./pages/newsDetail";
import NewsSearch from "./pages/newsSearch";
import LingGongBao from "./pages/lingongBao";

function App () {
  return (
    <Provider {...stores}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/job/:activeTab" component={Job} />
          <Route exact path="/jobDetail/:jobId" component={JobDetail} />
          <Route exact path="/server" component={Server} />
          <Route exact path="/hrsass" component={Hrsass} />
          <Route exact path="/lingGong" component={LingGong} />
          <Route exact path="/xiaowei" component={Xiaowei} />
          <Route exact path="/clientExample" component={ClientExample} />
          <Route exact path="/clientDetail/:id" component={ClientDetail} />
          <Route exact path="/about" component={About} />
          <Route exact path="/about/:tab" component={About} />
          <Route exact path="/about/:tab/:area" component={About} />
          <Route exact path="/news" component={News} />
          <Route exact path="/newsMore" component={NewsMore} />
          <Route exact path="/newsMore/:type" component={NewsMore} />
          <Route exact path="/newsDetail/:newsId" component={NewsDetail} />
          <Route exact path="/newsSearch" component={NewsSearch} />
          <Route exact path="/lingongBao" component={LingGongBao} />
          <Route component={Home} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
