import React, { useState } from 'react';
import Title from '../../../../component/Title';
import './index.scss';

const itemConfig = [
	{
		title: '生活服务类',
		icon: 'https://imagesize.hrwork.com/sp/files/72b7a4d6-e337-473f-8fd6-f7c6cb3b9130.png',
		hoverIcon:
			'https://imagesize.hrwork.com/sp/files/da6abdaf-11d2-458a-a1fa-c8a00e9cef82.png',
	},
	// {
	//   title: "物流配送类",
	//   icon: "https://imagesize.hrwork.com/sp/files/e8b33e52-bba8-4921-9790-8a58e599b364.png",
	//   hoverIcon: "https://imagesize.hrwork.com/sp/files/913a9ff3-6c0b-48c5-955b-de9e9002053c.png"
	// },
	{
		title: '传统企业',
		icon: 'https://imagesize.hrwork.com/sp/files/1478cf85-595d-4fbe-ab50-8384ad8f34c3.png',
		hoverIcon:
			'https://imagesize.hrwork.com/sp/files/af40a889-1d2f-4d51-95ee-c03c09700989.png',
	},
	{
		title: '互联网平台',
		icon: 'https://imagesize.hrwork.com/sp/files/ee7de01d-5b10-4fac-96a9-94e01bc90cc5.png',
		hoverIcon:
			'https://imagesize.hrwork.com/sp/files/4b10d20c-451c-4f55-99f4-3893be3b33dd.png',
	},
	// {
	// 	title: '咨询营销类',
	// 	icon: 'https://imagesize.hrwork.com/sp/files/4a293fc5-5081-4765-bb00-409c5c558646.png',
	// 	hoverIcon:
	// 		'https://imagesize.hrwork.com/sp/files/3fae7e32-1e44-48a0-9c47-f718aeee3439.png',
	// },
	// {
	// 	title: '教育平台类',
	// 	icon: 'https://imagesize.hrwork.com/sp/files/95f6e967-9a5a-4599-94cf-fbbe7a0c105c.png',
	// 	hoverIcon:
	// 		'https://imagesize.hrwork.com/sp/files/837cedb8-a656-4c49-862c-af5f837cea14.png',
	// },
	{
		title: '培训服务类',
		icon: 'https://imagesize.hrwork.com/sp/files/1468643c-ffe9-49b0-ad55-a2884d53e252.png',
		hoverIcon:
			'https://imagesize.hrwork.com/sp/files/9b32055a-2231-43c1-8baf-9adc111215d6.png',
	},
	// {
	//   title: "呼叫中心类",
	//   icon: "https://imagesize.hrwork.com/sp/files/1a84af95-f2be-4518-a257-d75e72a4c7c1.png",
	//   hoverIcon: "https://imagesize.hrwork.com/sp/files/2bd2998d-e759-4996-bdff-1d6af3b7c35c.png"
	// },
];

function Item({ data: { title, icon, hoverIcon } }) {
	const [imgSrc, setImgSrc] = useState(icon);

	return (
		<div
			onMouseOver={() => setImgSrc(hoverIcon)}
			onMouseOut={() => setImgSrc(icon)}
			className="item bcf flex jc ac fdc mb30"
		>
			<img width="60" src={imgSrc} alt="" />
			<span className="fs20 mt20">{title}</span>
		</div>
	);
}

function Industry() {
	return (
		<div className="home-industry mb50">
			<Title type="home-industry" titles={['我们服务的行业']} />
			<div className="mxauto flex fw jsb home-industry-content">
				{itemConfig.map((data) => (
					<Item key={data.title} data={data} />
				))}
			</div>
		</div>
	);
}
export default Industry;
