import React, { PureComponent } from 'react';
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Tab from "./component/Tab";
import Main from "./component/Main";

class News extends PureComponent {
  onTabChange = info => {
    console.log(info)
  }
  render () {
    return (
      <>
        <Header />
        <Tab onTabChange={this.onTabChange} />
        <Main />
        <Footer />
      </>
    )
  }
}
export default News;