import React from 'react';
import "./index.scss";
import classnames from "classnames";

class WorkCard extends React.Component {
  render () {
    const { type, icon, tip, title, to } = this.props;
    if (to) {
      return (
        <a href={to} target="_blank" >
          <div className={classnames("work-card-box", {
            [type]: !!type,
          })}>
            <img width="62" alt="" src={icon} />
            <div>
              <p className="fs30 c4e fwb mt30 mb10">{title}</p>
              <span>{tip}</span>
            </div>
          </div>
        </a>
      )
    } else {
      return (
        <div className={classnames("work-card-box", {
          [type]: !!type,
        })}>
          <img width="62" alt="" src={icon} />
          <div>
            <p className="fs30 c4e fwb mt30 mb10">{title}</p>
            <span>{tip}</span>
          </div>
        </div>
      )
    }
  }
}

export default WorkCard;