import React from 'react';
import "./index.scss";
import RcTooltip from 'rc-tooltip';

class Tooltip extends React.Component {
  
  render () {
    const { children, placement, overlay } = this.props;
    return (
      <RcTooltip prefixCls="duomi-tooltip" placement={placement} trigger={['hover']} overlay={overlay}>
        {children}
      </RcTooltip>
    )
  }
}

export default Tooltip;