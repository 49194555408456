import React from 'react';
import './index.scss';
import Tooltip from '../Tooltip';
import tools from '../../utils/tools';
import classnames from 'classnames';
const Overlay400 = () => <span className="overlay-400-tip">400-8787-282</span>;
const WeixinCode = () => (
	<div className="weixn-code-box">
		<img
			width="100%"
			src="https://imagesize.hrwork.com/sp/files/ab49e139-1133-4a28-b93b-ab489b333129.jpeg"
			alt=""
		/>
	</div>
);

class Fixed extends React.Component {
	state = {
		showTotop: false,
	};
	componentDidMount() {
		this.scrollHandler = window.addEventListener('scroll', () => {
			const y = tools.getWindowScrollY();
			if (y > this.props.distance && !this.state.showTotop) {
				this.setState({
					showTotop: true,
				});
			} else if (y <= this.props.distance && this.state.showTotop) {
				this.setState({
					showTotop: false,
				});
			}
		});
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
		if (this.scrollHandler) {
			this.scrollHandler.remove();
		}
	}

	goTop = () => {
		const me = this;
		this.scrollTo(me.props.to, me.props.duration, me.props.onTotopEnd);
	};

	scrollTo(to, duration, callback) {
		const me = this;
		if (duration <= 0) {
			if (callback) {
				callback();
			}
			return;
		}
		const y = tools.getWindowScrollY();
		const difference = to - y;
		const perTick = (difference / duration) * 10;
		me.timer = setTimeout(() => {
			const targetScrollY = y + perTick;
			tools.setWindowScrollY(targetScrollY < to ? to : targetScrollY);
			me.scrollTo(to, duration - 10, callback);
		}, 10);
	}

	render() {
		const { showPublish, showJob, showWx, hideApp } = this.props;
		const { showTotop } = this.state;

		return (
			<div
				className={classnames('fixed-box', {
					show: showTotop,
				})}
			>
				{showPublish && (
					<>
						<div className="fixed-item fixed-item-publish">
							<i className="icon"></i>
							<span>我要发布</span>
						</div>
						<span className="line"></span>
					</>
				)}

				{showJob && (
					<>
						<div className="fixed-item fixed-item-job">
							<i className="icon"></i>
							<span>我要招聘</span>
						</div>
						<span className="line"></span>
					</>
				)}

				{/* 
        <Tooltip placement="left" overlay={Overlay400}>
          <div className="fixed-item fixed-item-400">
            <i className="icon"></i>
            <span>400热线</span>
          </div>
        </Tooltip>
        <span className="line"></span> */}

				{/* {
          !hideApp && <><div className="fixed-item fixed-item-app">
            <i className="icon"></i>
            <span>APP下载</span>
          </div>
            <div className="line"></div></>
        } */}

				{showWx && (
					<>
						{' '}
						<Tooltip placement="left" overlay={WeixinCode}>
							<div className="fixed-item fixed-item-wexin">
								<i className="icon"></i>
								<span>微信公众号</span>
							</div>
						</Tooltip>
						<div className="line"></div>
					</>
				)}

				<div
					onClick={this.goTop}
					className="fixed-item fixed-item-totop"
				>
					<i className="icon"></i>
					<span>返回顶部</span>
				</div>
			</div>
		);
	}
}

Fixed.defaultProps = {
	distance: 1000,
	duration: 600,
	to: 10,
	onTotopEnd: () => {},
};

export default Fixed;
