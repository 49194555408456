import React, { PureComponent } from 'react';
import "./index.scss";
import classnames from "classnames";

class Nav extends PureComponent {
  state = {
    activeTab: ""
  }
  componentDidMount() {
    this.setState({
      activeTab: this.props.activeTab
    })
  }
  tabChange = info => {
    this.setState({
      activeTab: info
    });
    this.props.onTabChnage(info)
  }
  render() {
    const { activeTab } = this.state;
    return (
      <div className="nav-box">
        <div onClick={() => this.tabChange("zhong")} className={classnames("tab-item", { "active": activeTab === "zhong" })}>众包招聘</div>
        <div className="gap">/</div>
        <div onClick={() => this.tabChange("jian")} className={classnames("tab-item", { "active": activeTab === "jian" })}>兼职招聘</div>
      </div>
    )
  }
}

export default Nav;
