import React from 'react';
import NewsItem from "../NewsItem";
import Pagination from "../../../../component/Pagination";
import "./index.scss";

function Content () {
  return (
    <div className="br6 bf w1200 mxauto mt30 bs66 pl30 pr30 pb40 mb100 m-news-more-content">
      <NewsItem />
      <NewsItem />
      <NewsItem />
      <NewsItem />
      <NewsItem />
      <NewsItem />
      <NewsItem />
      <Pagination total={20} />
    </div>
  )
}

export default Content;