import React, { PureComponent } from 'react';
import "./index.scss";
import BaomingDialog from "../BaomingDialog";
import { Link } from 'react-router-dom';


class JobCard extends PureComponent {
  state = {
    isShowBaomingDialog: false
  }
  handleShowBaomingDialog = () => {
    this.setState({
      isShowBaomingDialog: true
    })
  }
  handleHideBaomingDialog = () => {
    this.setState({
      isShowBaomingDialog: false
    })
  }
  render () {
    const { isShowBaomingDialog } = this.state;
    return (
      <div className="job-card-box">
        <div className="title-box">
          <Link target="_blank" to="/jobDetail/2"><span className="name">便利蜂视频审核</span></Link>
          <span className="jipin">急聘</span>
          {/* <span className="ziying">自营</span> */}
        </div>
        <div className="job-detail">
          <div className="job-info">
            <div className="job-info-line">
              <p>
                <span className="label">工作时间</span>
                <span className="value">全职工作</span>
              </p>
              <p>
                <span className="label">工作类型</span>
                <span className="value">审核录入</span>
              </p>
            </div>
            <div className="job-info-line">
              <p>
                <span className="label">工作地点</span>
                <span className="value">北京市  朝阳区</span>
              </p>
              <p>
                <span className="label">招聘人数</span>
                <span className="value">50人</span>
              </p>
            </div>
          </div>
          <div className="job-action">
            <p className="xinzi">
              <span>5K~8K</span>
              <span>元/月</span>
              <span>月结</span>
            </p>
            <div onClick={this.handleShowBaomingDialog} className="join">报名参加</div>
          </div>
        </div>

        <BaomingDialog
          visible={isShowBaomingDialog}
          close={this.handleHideBaomingDialog}
        />
      </div>
    )
  }
}

export default JobCard;