import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";

const Item = props => <div style={{ width: props.width }} className="item bf pr br6">
  <div className="c4e fs30 fwb mb10 title">{props.title}</div>
  {
    props.tips.map((tip, i) => <p className="c9 fs20 lh40" key={i}>{tip}</p>)
  }
  <div className="icon">
    <img width="170" src={props.icon} alt="" />
  </div>
</div>

class Server extends PureComponent {
  render () {
    return (
      <div className="lg-server-box mb80">
        <Title type="lingGong-server" titles={["我们的服务"]} />
        <div className="flex w1200 mxauto jsb m-lg-server-item">
          <Item width="370px" title="人员招聘" tips={["· 招聘项目运作", "· 保质保量交付", "· 100%满编"]} icon="https://imagesize.hrwork.com/sp/files/b9235693-9d0b-4e95-b882-c2cad7ba4527.png" />
          <Item width="370px" title="人事服务" tips={["· 电子化入离职及合同管理", "· 移动考勤机请休假管理", "· 薪酬社保系统核算", "· 员工自助查询及在线咨询"]} icon="https://imagesize.hrwork.com/sp/files/cc44d197-612f-44ff-a0a4-ab6ae822d4e8.png" />
          <Item width="370px" title="专业服务" tips={["· 技能培训", "· 流失管理", "· 绩效管理"]} icon="https://imagesize.hrwork.com/sp/files/ceba2bfd-9b22-493b-821b-6f91cab93937.png" />
          <div className="hide m-item">
            <Item width="580px" title="风险管控" tips={["· 降低用工成本", "· 合法合规用工"]} icon="https://imagesize.hrwork.com/sp/files/7127c878-41a2-42ea-a76c-0eddea532867.png" />
          </div>

        </div>
        <div className="flex w1200 mxauto jsb mt40 m-lg-server-item-big m-lg-server-item">
          <div className="m-hide">
            <Item width="580px" title="风险管控" tips={["· 降低用工成本", "· 合法合规用工"]} icon="https://imagesize.hrwork.com/sp/files/7127c878-41a2-42ea-a76c-0eddea532867.png" />
          </div>
          <Item width="580px" title="增值服务" tips={["· 人力资源管理报告", "· 职业话培训"]} icon="https://imagesize.hrwork.com/sp/files/3ed9205c-9897-46d7-b6f7-639f6390aeb8.png" />
        </div>
      </div>
    )
  }
}
export default Server;