import React from 'react';
import Title from '../../../../component/Title';
import './index.scss';

function Step({ num }) {
	return <div className="step cf tac">{num}</div>;
}

function Intro() {
	return (
		<div className="bf bao-intro-com">
			<Title type="bao-intro" titles={['投保须知']} />
			<div className="w1200 mxauto bao-intro-main">
				<div className="flex mb20">
					<Step num="01" />
					<div>
						<p className="fs28 c4e fwb title">承保范围</p>
						<p className="fs24 c6 lh50 tip">
							目前该公司承保区域限于中国境内，不包括港澳台地区；各类机关、企事业单位、个体经济组织以及其他组织，均可作为本保险的投保人和被保险人；
						</p>
					</div>
				</div>

				<div className="flex mb20">
					<Step num="02" />
					<div>
						<p className="fs28 c4e fwb title">承保年龄及职业</p>
						<p className="fs24 c6 lh50 tip">
							本保单仅承保1-4类职业雇员，年龄范围为16-65周岁，且保险人严格按投保人提供的人员职业类别办理保
							险，对超出投保时约定或隐瞒被保险人真实职业类别的情形时，保险人将根据《保险法》第十六条之规定对
							发生保险事故不承担赔偿或给付保险金责任；可承保职业详情请点击职业类别表；
						</p>
					</div>
				</div>

				<div className="flex mb20">
					<Step num="03" />
					<div>
						<p className="fs28 c4e fwb title">保险起期</p>
						<p className="fs24 c6 lh50 tip">
							以实际投保确认生效时间为准；
						</p>
					</div>
				</div>

				<div className="flex mb20">
					<Step num="04" />
					<div>
						<p className="fs28 c4e fwb title">伤残等级比例</p>
						<p className="fs24 c6 lh50 tip">
							伤残等级比例为：
							一级100%、二级90%、三级80%、四级70%、
							五级60%、六级50%、七级40%、八级
							30%、九级20%、十级10%；
						</p>
					</div>
				</div>

				<div className="flex mb20">
					<Step num="05" />
					<div>
						<p className="fs28 c4e fwb title">告知义务</p>
						<p className="fs24 c6 lh50 tip">
							在投保本产品前您应履行相应的如实告知义务，具体如下：
						</p>
						<p className="fs24 c6 lh50 tip">
							•
							投保人或被保险人应如实填写投保信息,并就保险公司提出的询问据实告知,否则保险公司有权根据《中华
							人民共和国保险法》第十六条的规定解除保险合同且不承担赔偿责任:订立保险合同时,保险公司就保险标
							的或者被保险人的有关情况提出询问,投保人应当如实告知；
						</p>
						<p className="fs24 c6 lh50 tip">
							<b className="c4e">•</b>{' '}
							投保人故意或者因重大过失未履行前款规定的如实告知义务，足以影响保险公司决定是否同意承保或者提高保险费率的，保险公司有权解除合同；
						</p>
						<p className="fs24 c6 lh50 tip">
							•
							投保人故意不履行如实告知义务的，保险公司对于合同解除前发生的保险事故，不承担赔偿责任，并不退还保险费；
						</p>
						<p className="fs24 c6 lh50 tip">
							•
							投保人因重大过失未履行如实告知义务，对保险事故的发生有严重影响的，保险公司对于合同解除前发生的保险事故，不承担赔偿责任，并不退还保险费；
						</p>
					</div>
				</div>

				<div className="flex mb20">
					<Step num="06" />
					<div>
						<p className="fs28 c4e fwb title">理赔服务</p>
						<p className="fs24 c6 lh50 tip">
							后续保全、理赔等相关事务，可由金闪闪网协助办理，如需咨询或理赔服务，请联系保准牛客服热线
						</p>
						<p
							style={{ color: '#FF6700' }}
							className="fs24 lh50 tip"
						>
							[ 400-8787-282 ]
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Intro;
