import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";
import classnames from "classnames";

const Item = props => <div className={classnames("item bf bs66 br6 flex ac fdc pr", {
  mobile: !!props.m
})}>
  <div className={classnames("icon", { [props.type]: !!props.type })}></div>
  <p className="c6 fs20 fwb lh28 mt20">{props.title}</p>
  <p className="c6 fs20 fwb lh28">{props.tip}</p>
</div>

class Server extends PureComponent {
  render () {
    return (
      <div className="xw-server-box">
        <Title type="xiaowei-server" titles={["我们的服务"]} tip="帮助小微企业、个体工商解决社保公积金缴纳难题" />
        <div className="flex w1200 mxauto jsb mt40 m-xw-server-item">
          <Item type="one" title="小微企业缺少" tip="人事部门" />
          <Item type="two" title="异地缴纳" tip="操作不便" />
          <Item type="three" title="担心社保断缴" tip="影响购房购车" />
          <Item m type="fore" title="市面上代缴" tip="机构成本高" />
          <Item m type="five" title="五险一金政策" tip="复杂多变" />
        </div>
      </div>
    )
  }
}
export default Server;