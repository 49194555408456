import React, { useState } from 'react';
import "./index.scss";
import classnames from "classnames";
import newsType from "../../../../data/newsType.json";




function Tab ({ onTabChange }) {
  const [activeTab, setActiveTab] = useState("全部")
  const tabChange = ({ name, id }) => {
    setActiveTab(name);
    onTabChange({ name, id })
  }
  return (
    <div className="news-tab-box w1200 mxauto bf mt30 br6 bs66 flex ac">
      <p className="fs20 fwb c4e lh28 tac tab-item">
        <span onClick={() => tabChange({ name: "全部", id: null })} className={classnames("cp", {
          "active": activeTab === "全部"
        })}>全部</span>
      </p>
      {
        newsType.map(({ name, id }) => <p key={id} className="fs20 fwb c4e lh28 tac tab-item pr line">
          <span onClick={() => tabChange({ name, id })} className={classnames("cp", {
            "active": activeTab === name
          })}>{name}</span>
        </p>)
      }
    </div>
  )
}

export default Tab;