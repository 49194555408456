import React from 'react';
import Title from '../../../../component/Title';
import './index.scss';

const itemConfig = [
	{
		title: '水果商店小程序模版示例',
		imgList: [
			'https://image.vip-ami.cn/sp/files/73e82e98-7237-47a2-aff8-08b28a5a72b1.jpeg',
			'https://image.vip-ami.cn/sp/files/88bea85d-9f02-4592-8875-3a50e076c45b.jpeg',
		],
	},

	{
		title: '母婴商城小程序模版示例',
		imgList: [
			'https://image.vip-ami.cn/sp/files/dd62ffa2-28d5-4381-8fa9-1f2a6043dbf0.jpeg',
			'https://image.vip-ami.cn/sp/files/962cf3eb-5737-4437-9d01-60abb1827a12.jpeg',
		],
	},
	{
		title: '点单小程序模版示例',
		imgList: [
			'https://image.vip-ami.cn/sp/files/039a370a-93ef-480a-996a-fd8e9803408d.jpeg',
			'https://image.vip-ami.cn/sp/files/c7fd19f0-a4a4-4b90-9752-fad2350f5b19.jpeg',
		],
	},
];

function MiniAppTemplate() {
	return (
		<div className="home-industry mb50">
			<div className="mxauto flex fw jsb home-industry-content">
				{itemConfig.map((data) => (
					<div className="detail-content">
						<Title type="home-industry" titles={[data.title]} />
						<div className="template-content">
							{(data.imgList || []).map((item) => {
								return (
									<img
										className="template-img"
										src={item}
										alt=""
									></img>
								);
							})}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
export default MiniAppTemplate;
