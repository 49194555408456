import React, { PureComponent } from 'react';
import RcDialog from "rc-dialog";
import "./index.scss";



class Dialog extends PureComponent {
  
  render () {
    const { children } = this.props;
    return (
      <RcDialog
        maskClosable={false}
        {...this.props}
        closeIcon={<img width="30" src="https://imagesize.hrwork.com/sp/files/4c57fef1-dfbd-47dc-8600-b1f934a134c2.png" alt="" />}>
        {children}
      </RcDialog>
    )
  }
}

export default Dialog;