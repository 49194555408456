import React from 'react';
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import newsData from "../../data/news.json";
import "./index.scss";


function P ({ text }) {
  return <p className="c4e fs18 lh60 m-p-text">{text}</p>
}


function NewsDetail (props) {
  const newsId = Number(props.match.params.newsId);
  const { title, content, typeStr, pdf } = newsData.find(news => news.id === newsId);
  const nexData = newsData.find(news => news.id === newsId + 1);
  const preData = newsData.find(news => news.id === newsId - 1);
  return (
    <>
      <Header />
      <div className="w1200 bf mxauto mt30 bs66 pl30 pr25 pt30 pb20 mb100 m-news-detail-box">
        <p className="c4e fs30 fwb lh42 title">{title}</p>
        <p className="c9 fs20 lh28 mt10 pb20 bbsolid-dd mb20 time-box">{typeStr}  |  2019年08月20日</p>
        {
          content.map(({ text, type }, i) => type === "p" ? <P key={i} text={text} /> : type === "img" ? <p key={i} className="tac oh m-p-text m-p-img"><img src={text} /></p> : type === "imgTip" ? <p className="tac mb20 m-p-text" key={i}>{text}</p> : <p className="m-p-text" key={i}>{text}</p>)
        }
        {
          pdf && <a className="cf60 u m-pdf ib" target="_blank" href={pdf.url}>{pdf.name}</a>
        }
        <div className="bbsolid-dd mt20 mb20 m-line"></div>
        {
          preData && <a href={`/newsDetail/${preData.id}`} className="fs20 lh60 c4e cp hover-cf60 pre"><span className="hide m-tip">{'<<'}</span>上一篇  <span className="m-text">{preData.title}</span></a>
        }
        {preData && <br className="m-br" />}
        
        {
          nexData && <a href={`/newsDetail/${nexData.id}`} className="fs20 lh60 c4e cp hover-cf60 pre next ib">下一篇  <span className="m-text">{nexData.title}</span><span className="hide m-tip">{'>>'}</span></a>
        }
        <div className="hide m-clear"></div>
      </div>
      <Footer />
    </>
  )
}
export default NewsDetail;