import React, { PureComponent } from 'react';
import "./index.scss";
class Input extends PureComponent {
  render () {
    const { placeholder = "请输入", onChange } = this.props
    return (
      <div className="input-box">
        <input placeholder={placeholder} onChange={onChange} />
      </div>
    )
  }
}
export default Input