import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";

class Process extends PureComponent {
  render () {
    return (
      <div className="xiaowei-process-box">
        <Title type="xiaowei-process" titles={["线上服务流程"]} />
        <div className="flex w1200 mxauto m-xiaowei-process-box">
          <div className="video-box br6 pr oh">
            <div className="left-bg bg"></div>
            <div className="content pr br6">
              <img width="100%" src="https://imagesize.hrwork.com/sp/files/dd92ed6a-585d-4b98-a38d-caadeb37b5c3.png" alt="" />
            </div>
            <div className="right-bg bg"></div>
          </div>
          <div className="process c4e fs30">
            <p>①  实名认证注册</p>
            <p>②  参保信息填写</p>
            <p>③  下单付款</p>
            <p>④  反馈订单结果</p>
          </div>
        </div>
      </div>
    )
  }
}
export default Process;