import React, { useState } from 'react';
import './index.scss';

function MiniCardTabItem({ type }) {
	return (
		<div className="news-mini-tab-item pr">
			<span className="c4e fs20 fwb lh28 ellipsis">
				【金闪闪新闻】以科技赋能招聘，金闪闪入选中国市以科技赋能招聘，金闪闪入选中国市以科技赋能招聘，金闪闪入选中国市
			</span>
		</div>
	);
}

export default MiniCardTabItem;
