import React, { PureComponent } from 'react';
import "./index.scss";

class DetailHeader extends PureComponent {
  render () {
    const { data: { name, companyName, trade, service, detailIcon } } = this.props;
    return (
      <div className="detail-header-box">
        <div className="w1200 mxauto content pt1 pc-detail-header-main">
          <p className="cf fwb fs40 title mt40 lh56">{name}</p>
          <div className="mt20 flex ac">
            <div className="one flex shrink-0">
              <div className="icon">
                <img width="100%" src={detailIcon} alt="" />
              </div>
              <p className="ml20 mt5">
                <span className="cf fs20 m-tip">企业名称</span>
                <br />
                <span className="cf fs26 mt10 ib m-v">{companyName}</span>
              </p>
            </div>

            <div className="line"></div>

            <div className="two mt5 shrink-0">
              <p className="cf fs20 m-tip">所属行业</p>
              <p className="cf fs26 mt10 m-v">{trade}</p>
            </div>

            <div className="line"></div>

            <div className="three mt5">
              <p className="cf fs20 m-tip">产品服务</p>
              <p className="cf fs26 mt10 m-v">{service}</p>
            </div>

          </div>
        </div>

        <div className="hide m-detail-header-main">
          <div className="icon-box">
            <img width="100%" src={detailIcon} alt="" />
          </div>
          <div className="flex-1 m-ml20">
            <p className="m-fs30 cf fwb">{name}</p>
            <p className="cf m-fs18 m-mt10">{companyName} | {trade} | {service}</p>
          </div>
        </div>
      </div>
    )
  }
}
export default DetailHeader;