import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";

const RightItem = props =>  <div className="item right-item mb40 ml40">
<p className="c4e fs30 fwb">{props.title}</p>
{
  props.tips.map((tip, i) => <p className="c6 fs20 lh40 mt10" key={i}>· {tip}</p>)
}
</div>

class Platform extends PureComponent {
  render () {
    return (
      <div className="hrsass-platform-box pt1">
        <Title type="hrsass-platform" titles={["以数据智能，搭建互联网+人社基层服务平台"]} />
        <div className="w1200 mxauto flex content">
          <div className="left item">
            <p className="c4e fs30 fwb">智慧人力资源共享服务中心</p>
            <p className="c6 fs20 lh40 mt10">· 人力资源共享中心</p>
            <p className="c6 fs20 lh40">· 企业共享服务中心/人力资源研究院</p>
            <p className="c6 fs20 lh40">· 中国智慧人力资源服务骨干网CSSN</p>
          </div>
          <div className="right flex fw">
            <RightItem title="数字政务服务" tips={["政务一体化经办业务系统", "基层服务平台"]} />
            <RightItem title="智慧企业服务" tips={["企业上云服务", "共享IT设备租赁", "智能办公"]} />
            <RightItem title="大数据平台" tips={["数据资产管理", "数据分析平台"]} />
            <RightItem title="可视化决策" tips={["领导驾驶舱", "业务监管分析可视化"]} />
          </div>
        </div>
      </div>
    )
  }
}

export default Platform;