import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";

const Item = props => <div className="item flex fdc ac">
  <div className="icon-box flex ac jc bs33">
    <img width="85" src={props.icon} alt="" />
  </div>
  <p className="cf fs30 fwb lh42 mt20">{props.title}</p>
  <p className="cf fs20 lh30 mt10 tac">{props.tip}</p>
</div>

class Advantage extends PureComponent {
  render () {
    return (
      <div className="xiaowei-adv-box pt1 mt80">
        <Title type="xiaowei-adv" titles={["我们的优势"]} />
        <div className="flex w1200 mxauto jsb m-xiaowei-adv-box">
          <Item icon="https://imagesize.hrwork.com/sp/files/9b07f2b6-eda7-4a3c-895a-8a2d1b50a96f.png" title="缴纳快速便捷" tip="关注微信公众号一键缴纳" />
          <Item icon="https://imagesize.hrwork.com/sp/files/32a5568e-aa6e-4898-a57a-0c3c65087a38.png" title="私人客户解答" tip="可添加小微客服微信号，参保状态短信通知" />
          <Item icon="https://imagesize.hrwork.com/sp/files/c26da96c-ccf6-410a-883e-236abb6542a0.png" title="缴纳节点提醒" tip="按月缴纳，临近截止日通知不再断缴" />
          <Item icon="https://imagesize.hrwork.com/sp/files/af7a6a7a-56ce-46e6-b1f5-eaa197115870.png" title="服务全流程可查" tip="参保数据实时同步，订单反馈在线可查" />
        </div>
      </div>
    )
  }
}
export default Advantage;