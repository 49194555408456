import React from 'react';
import "./index.scss";
import M_Card from "../../../newsMore/component/NewsItem";

function Card () {
  return (
    <>
      <div className="news-card-box br6 bf oh mb30 bs66 pc-news-card-box">
        <div className="img-box pr">
          <p className="cp fs20 lh40 cf tac pa bml">阅读全文</p>
        </div>
        <div className="pt20 pl20 pr30">
          <p className="fs30 c4e fwb lh42">经营风险管理能力</p>
          <p className="fs20 c9 lh30 p-text mt8">多维度风险把控及预防机制，专业化的用工风险与劳务纠纷处理的能力
  ；实时管理及服务百万在岗外灵工，打造共享人才池造共享人才打多维度风险把控及预防机制，专业化的用工风险与劳务纠纷处理的能力
；实时管理及服务百万在岗外灵工，打造共享人才池造共享人才打实时管理及服务百万在岗外灵工，打造共享人才池造共享人才打</p>
        </div>
      </div>
      <div className="hide m-news-card-box">
        <M_Card />

      </div>
    </>
  )
}

export default Card;