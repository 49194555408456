import React, { PureComponent, useState } from 'react';
import './index.scss';
import classnames from 'classnames';
import newsData from '../../../../data/news.json';
import Title from '../../../../component/Title';

const tabs = [
	{
		name: '行业资讯',
		moreText: '更多资讯',
		id: 1,
		typeId: 1,
		dataList: newsData.filter((item) => item.typeId === 1).slice(0, 5),
	},
	{
		name: '政策法规',
		moreText: '更多法规',
		id: 2,
		typeId: 2,
		dataList: newsData.filter((item) => item.typeId === 2).slice(0, 5),
	},
	{
		name: '金闪闪新闻',
		id: 3,
		moreText: '更多新闻',
		typeId: 3,
		dataList: newsData.filter((item) => item.typeId === 3).slice(0, 5),
	},
];

function M_Tab({ tabChange }) {
	const [activeId, setActiveId] = useState(1);
	const change = (info) => {
		setActiveId(info.id);
		tabChange(info);
	};
	return (
		<div className="flex tab-box ac mb30">
			{tabs.map(({ name, id, dataList, moreText }) => (
				<p
					onClick={() => change({ name, id, dataList, moreText })}
					className={classnames('c4e fs20 cp', {
						active: activeId === id,
					})}
					key={id}
				>
					{name}
				</p>
			))}
		</div>
	);
}

class News extends PureComponent {
	state = {
		newsDataList: newsData.filter((item) => item.typeId === 1).slice(0, 5),
		moreText: '更多资讯',
		activeTitle: '行业资讯',
	};

	tabChange = (info) => {
		this.setState({
			newsDataList: info.dataList,
			moreText: info.moreText,
			activeTitle: info.name,
		});
	};

	render() {
		const { newsDataList, moreText, activeTitle } = this.state;

		return (
			<div className="home-about-us">
				<Title type="home-about" titles={['关于我们']} />
				<div className="news-box flex w1200 jsb mxauto ac">
					<div className="vedio">
						<video
							width="100%"
							controls
							src="https://cdn.hrwork.com/fore/duomi-official/publish/build/static/media/home.252d8aba.mp4"
						></video>
					</div>
					<div className="news bcf pl20 pr20 pb20">
						<M_Tab tabChange={this.tabChange} />
						{newsDataList.map(({ id, title }) => (
							<p className="teo news-title fs20 mb30" key={id}>
								<a className="c4e" href={`/newsDetail/${id}`}>
									{title}
								</a>
							</p>
						))}
						<a
							href={`/newsMore/${activeTitle}`}
							className="more-box flex ac jsb"
						>
							<span className="fs20">{moreText}</span>
							<img
								width="30"
								src="https://imagesize.hrwork.com/sp/files/9af76184-bdf5-4189-88e5-1cb25d9096de.png"
								alt=""
							/>
						</a>
					</div>
				</div>
			</div>
		);
	}
}

export default News;
