import React, { PureComponent } from 'react';
import Title from "../../../../component/Title";

import "./index.scss";

class Server extends PureComponent {
  render () {
    return (
      <div className="server-box">
        <Title type="server" titles={["致力于为企业提供人才服务"]} />
        <div className="server-content">
          <p>为能更好地加速企业人才，特别是技术人才的增值，</p>
          <p>我们在互联网、大数据、云计算和人工智能技术应用的基础上，构建了线上线下融合的职业生态圈成长模式</p>
          <div className="server-num-box">
            <div className="server-num-item">
              <span className="num">60+</span>
              <span className="tip">线下分支机构</span>
            </div>

            <div className="server-num-item">
              <span className="num">8000+</span>
              <span className="tip">累计服务企业超8000家</span>
            </div>

            <div className="server-num-item">
              <span className="num">30+</span>
              <span className="tip">行业代表性解决方案</span>
            </div>

            <div className="server-num-item">
              <span className="num">60w+</span>
              <span className="tip">累计服务人员超60w</span>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default Server;
