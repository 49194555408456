import React, { PureComponent } from 'react';
import "./index.scss";
import Dialog from "../Dialog";

class SubmitSuccess extends PureComponent {
  render () {
    const { onClose, children, visible } = this.props;
    return (
      <Dialog width={406} closable={false} visible={visible}>
        <div className="submit-success-dialog">
          <img onClick={onClose} width="30" className="close-btn" src="https://imagesize.hrwork.com/sp/files/e4b7569c-dfab-4913-a03f-1966e86dc8bd.png" alt="" />
          <div className="submit-success-dialog-content">
            <div className="submit-success-dialog-icon">
              <img src="https://imagesize.hrwork.com/sp/files/bc387677-3079-4a11-b818-23d7ef78e880.png" width="100%" />
            </div>
            <div className="submit-success-dialog-body">{children}</div>
          </div>
        </div>
      </Dialog>
    )
  }
}
export default SubmitSuccess;