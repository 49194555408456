import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";
import exzampleData from "../../../../data/exzample.json";
import CompanyCard from "../../../../component/CompanyCard";

const data = exzampleData.slice(0, 10)

const m_data = exzampleData.slice(0, 6)

class WorkTogether extends PureComponent {
  render () {
    return (
      <>
        <div className="work-together-box">
          <Title type="together" titles={["合作名企"]} />
          <div className="enterprise-box">
            <div className="bg"></div>
            {
              data.map(({ name, icon, hoverIcon }) => <CompanyCard light key={name} icon={icon} hoverIcon={hoverIcon} />)
            }
          </div>
          <a target="_blank" href="/clientExample"><div className="more-btn mxauto tac cp ccc hover-cf60 fs20">查看更多</div></a>
        </div>

        <div className="m-work-together-box hide">
          <Title type="together" titles={["合作名企"]} />
          <div className="enterprise-box">
            {
              m_data.map(({ name, icon, hoverIcon }) => <CompanyCard light key={name} icon={icon} hoverIcon={hoverIcon} />)
            }
          </div>
          {/* <Link target="_blank" to="/clientExample"><div className="more-btn mxauto tac cp ccc hover-cf60 fs20">查看更多</div></Link> */}
        </div>
      </>
    )
  }
}

export default WorkTogether;
