import React from 'react';
import './index.scss';
import Header from '../../component/Header';
import ServerDetail from './component/ServerDetail';
import Honor from './component/Honor';
import Follow from './component/Follow';
import Footer from '../../component/Footer';
import Fixed from '../../component/Fixed';
import Banner from '../../component/Banner';

const items = [
	{
		title: '金闪闪众包',
		image: 'https://imagesize.hrwork.com/sp/files/0a3d9f72-a42c-4cd2-afce-767d87d5eb50.jpg',
		m_image:
			'https://imagesize.hrwork.com/sp/files/7ecfcc0f-c2f9-4136-a833-10b10c66e7e8.jpg',
		tips: ['提高企业用工效率，优化用工结构'],
		key: 1,
	},
	{
		title: '金闪闪服务',
		image: 'https://imagesize.hrwork.com/sp/files/bd376f0f-9013-42a3-9543-48d527d12382.jpg',
		m_image:
			'https://imagesize.hrwork.com/sp/files/55fdfec5-ef00-4d48-9441-4770ca6ccb42.jpg',
		tips: ['社会化灵活用工服务平台'],
		key: 2,
	},
	{
		image: 'https://imagesize.hrwork.com/sp/files/61b19a77-0c83-4ac3-a864-98e704dbdb35.jpg',
		m_image:
			'https://imagesize.hrwork.com/sp/files/6b6aa489-85c8-4dd3-a3e4-8164dc25f77c.jpg',
		title: '灵活用工综合服务平台',
		tips: [
			'▪ 60+线下分支机构',
			'▪ 服务企业8000+家',
			'▪ 业务覆盖600+城',
			'▪ 服务60万+人次',
		],
		key: 3,
	},
];

class Server extends React.Component {
	componentDidMount() {
		document.title = '服务产品';
	}
	render() {
		return (
			<div className="page-server bf">
				<Fixed showWx />
				<Header activeIndex="1" />
				<Banner items={items} />
				<ServerDetail />
				<Honor />
				<Follow />
				<Footer />
			</div>
		);
	}
}

export default Server;
