import React, { PureComponent } from 'react';
import './index.scss';
import SubmitSuccess from '../../../../component/SubmitSuccess';

class BaomingSuccess extends PureComponent {
	render() {
		const { title, isWechat, visible, onClose } = this.props;
		return (
			<SubmitSuccess visible={visible} onClose={onClose}>
				<div className="bm-success-dialog-body">
					<p className="title">{title}</p>
					{isWechat ? (
						<p className="concat-tip">
							请添加微信主动联系商家，添加商家好友时请备注：
							<br />
							[金闪闪]姓名+性别+手机号+职位名
						</p>
					) : (
						<p className="concat-tip">
							请打电话主动联系商家，联系时请说明是在金闪闪上报名的
						</p>
					)}
					{isWechat ? (
						<div className="concat-method wechat">
							<img
								width="30"
								src="https://imagesize.hrwork.com/sp/files/c7d0b6fa-669e-424a-bd90-1313c5c5aa99.png"
								alt=""
							/>
							<span>13217165631</span>
						</div>
					) : (
						<div className="concat-method mobile">
							<img
								width="30"
								src="https://imagesize.hrwork.com/sp/files/035d81d7-b417-4735-8170-ab52b0a35119.png"
								alt=""
							/>
							<span>13217165631</span>
						</div>
					)}
				</div>
			</SubmitSuccess>
		);
	}
}

export default BaomingSuccess;
