import React, { PureComponent } from 'react';
import "./index.scss";
import Tab from "../../../../component/Tab";
import ExamplePlay from "../../../../component/Example";
import tradeData from "../../../../data/trade.json";
import serviceProductData from "../../../../data/serviceProduct.json";
import classnames from "classnames";
import exzampleData from "../../../../data/exzample.json";



const tradeAllData = [
  {
    "name": "全部",
    "id": null
  },
  ...tradeData
]

const serviceProductAllData = [
  {
    "name": "全部",
    "id": null
  },
  ...serviceProductData
]
class Example extends PureComponent {
  state = {
    activeTabKey: "1",
    tabsData: tradeAllData,
    tradeActiveTabId: null,
    serviceProductActiveTabId: null,

    exampleData: exzampleData, // 案例
  }
  onTabChange = ({ key, text }) => {
    const { tradeActiveTabId, serviceProductActiveTabId } = this.state;
    let id = key === "1" ? tradeActiveTabId : serviceProductActiveTabId;
    this.changeExampleData(key, id)
    this.setState({
      activeTabKey: key,
      tabsData: key === "1" ? tradeAllData : serviceProductAllData,
    })
  }

  // 修改实例数据
  changeExampleData = (activeTabKey, exampleTabId) => {
    if (activeTabKey === "1") { // 按行业
      this.setState({
        exampleData: exzampleData.filter(example => example.tradeTypeId.includes(exampleTabId) || exampleTabId === null)
      })
    } else {
      this.setState({
        exampleData: exzampleData.filter(example => example.serviceProductId.includes(exampleTabId) || exampleTabId === null)
      })
    }
  }

  tabCardClick = id => {
    const { activeTabKey } = this.state;
    this.changeExampleData(activeTabKey, id);
    if (activeTabKey === "1") { // 按行业
      this.setState({
        tradeActiveTabId: id,
      })
    } else { // 按服务产品
      this.setState({
        serviceProductActiveTabId: id,
      })
    }
  }

  render () {
    const { activeTabKey, tabsData, tradeActiveTabId, serviceProductActiveTabId, exampleData } = this.state;
    return (
      <div className="client-example-box w1200 mxauto pr">
        <div className="tac">
          <Tab defaultNotChange defaultActiveKey="1" onTabChange={this.onTabChange} items={[{ text: "按照行业查看", key: "1" }, { text: "按照服务产品查看", key: "2" }]} />
        </div>

        <div className="cf bs66 br6 flex fw mt20 mb40 min-h-140 m-client-example-box-tab">
          {
            tabsData.map(({ id, name }) => <p className="w200 tac h70 flex ac jc" key={name}><span onClick={() => this.tabCardClick(id)} className={classnames("c4e fs22 hover-cf60 cp", {
              "cf60": activeTabKey === "1" ? tradeActiveTabId === id : serviceProductActiveTabId === id
            })}>{name}</span></p>)
          }
          <i className="hide"></i>
        </div>

        <div className="flex fw jsb m-ex-box">
          {
            exampleData.map(({ companyName, name, companyIntro, exzampleIcon, id }) => <ExamplePlay companyName={companyName} mini companyIntro={companyIntro} exzampleIcon={exzampleIcon} key={name} id={id} mb />)
          }
          <i style={{ width: "373px", }} className="ib"></i>
          <i style={{ width: "373px", }} className="ib"></i>
          <i style={{ width: "373px", }} className="ib"></i>
        </div>
      </div>
    )
  }
}

export default Example;