import React, { PureComponent } from 'react';
import "./index.scss";
import Header from "../../component/Header";
import Footer from "../../component/Footer";
import Banner from "../../component/Banner";
import Server from "./component/Server";
import Process from "./component/Process";
import Advantage from "./component/Advantage";

const items = [
  {
    title: "小微社保",
    image: 'https://imagesize.hrwork.com/sp/files/a8ed49ea-5898-4d27-851d-9ae1470cba6b.jpg',
    m_image: "https://imagesize.hrwork.com/sp/files/5d3b2611-3e9a-4d99-b387-e9ff05b6112d.jpg",
    tips: [
      "24小时在线，足不出户缴社保"
    ],
    showBtn: true,
    key: 1
  },
  {
    title: "按月操作",
    image: 'https://imagesize.hrwork.com/sp/files/4e79d302-85aa-4b7d-a2ea-a94b4faee0b0.jpg',
    m_image: "https://imagesize.hrwork.com/sp/files/44ef6e02-9e41-469b-8fac-8241f1cf70e1.jpg",
    tips: [
      "跨城市联网"
    ],
    showBtn: true,
    key: 2
  },
  {
    title: "无论您因为什么原因需要缴纳社保",
    image: "https://imagesize.hrwork.com/sp/files/d70a9122-6034-41cc-b904-22e7109165b3.jpg",
    m_image: "https://imagesize.hrwork.com/sp/files/972061ab-ef61-4ca1-b3a8-269cb3c8a350.jpg",
    tips: [
      "我们帮您搞定"
    ],
    showBtn: true,
    key: 3
  },
]
class Xiaowei extends PureComponent {
  componentDidMount() {
    document.title = "小微社保通服务"
  }
  render () {
    return (
      <div className="page-xiaowei bf">
        <Header activeIndex="1" />
        <Banner items={items} />
        <Server />
        <Process />
        <Advantage />
        <Footer />
      </div>
    )
  }
}
export default Xiaowei;