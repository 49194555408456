import React, { PureComponent } from 'react';
import "./index.scss";
import classnames from "classnames";

class Tab extends PureComponent {
  state = {
    activeTabKey: ""
  }
  componentDidMount () {
    this.setState({
      activeTabKey: this.props.defaultActiveKey
    })
  }
  componentWillReceiveProps (newPros) {
    if (!newPros.defaultNotChange) {
      this.setState({
        activeTabKey: newPros.defaultActiveKey
      })
    }
  }
  onTabChange = info => {

    this.setState({
      activeTabKey: info.key
    });
    this.props.onTabChange(info);
  }
  render () {
    const { activeTabKey } = this.state;
    const { items = [] } = this.props
    return (
      <div className="tab-com bs66 ib br6 bf oh">
        {
          items.map(item => <p onClick={() => this.onTabChange(item)} className={classnames("item ib tac cp c4e fs26 fwb", {
            "active": activeTabKey === item.key
          })} key={item.key}>{item.text}</p>)
        }
      </div>
    )
  }
}
export default Tab;