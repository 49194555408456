import React from 'react';
import Title from "../../../../component/Title";
import "./index.scss";
function Item ({ icon, title }) {
  return (
    <div className="item tac">
      <div className="dot flex ac jc">
        <img width="60" src={icon} alt="" />
      </div>
      <p className="c4e fwb fs30 mt30">{title}</p>
    </div>
  )
}
function Advantage () {
  return (
    <div className="bf bao-advantage">
      <Title type="Advantage" titles={["我们的优势"]} />
      <div className="bao-advantage-main w1200 flex mxauto">
        <Item title="多种渠道" icon="https://imagesize.hrwork.com/sp/files/f9351153-e72a-4fb8-bfe1-d9da18869699.png" />
        <Item title="自由选择" icon="https://imagesize.hrwork.com/sp/files/242b1b83-1632-4a3b-8c30-70d5e876ba38.png" />
        <Item title="材料简单" icon="https://imagesize.hrwork.com/sp/files/909565a0-09c3-48b2-9f22-20a29eb29d74.png" />
        <Item title="安心快捷" icon="https://imagesize.hrwork.com/sp/files/4364f848-8839-4574-9886-f4748b6f10d8.png" />
      </div>
    </div>
  )
}

export default Advantage;


