import React, { useState } from 'react';
import './index.scss';
import classnames from 'classnames';

const topTabs = ['金闪闪新闻'];
const bottomTabs = ['行业资讯', '人事政策'];

function MiniCardTab({ onTabChange, type }) {
	const tabs = type === 'top' ? topTabs : bottomTabs;
	const [activeTab, setActiveTab] = useState(tabs[0]);
	const tabChange = (info) => {
		setActiveTab(info);
		onTabChange(info);
	};

	return (
		<div className="news-mini-card-tab-box flex ac jsb pr20 pl30">
			{tabs.map((tab) => (
				<p className="fs20 fwb c4e" key={tab}>
					<span
						onClick={() => tabChange(tab)}
						className={classnames('cp', {
							active: activeTab === tab,
						})}
					>
						{tab}
					</span>
				</p>
			))}
			<p className="more-btn bml flex ac jsb cp">
				<span className="cf fs20 fwb">更多</span>
				<img
					width="14"
					src="https://imagesize.hrwork.com/sp/files/0139f6d4-89e6-4d86-9c67-e2431706e765.png"
					alt=""
				/>
			</p>
		</div>
	);
}

export default MiniCardTab;
