import React from 'react';
import "./index.scss";
import Pagination from 'rc-pagination';

const itemRender = (current, type, element) => {
  if (type === 'prev') {
    return '上一页';
  }
  if (type === 'next') {
    return '下一页';
  }
  return element;
};

function Page(props) {
  return <Pagination {...props} itemRender={itemRender} />
}

export default Page