import React, { PureComponent } from 'react';
import "./index.scss";
import H1 from "../../../../component/H1";

class DetailContent extends PureComponent {
  render () {
    const { companyIntro, pain, method } = this.props.data
    return (
      <div className="bf client-detail-box">
        <div className="w1200 mxauto mt60 m-client-detail-main">
          <H1 title="企业介绍" />
          <div className="c4e fs18 mt20 lh50 mb60 m-p-box">
            {
              companyIntro.map((intro, i) => <p className="m-p" key={i}>{intro}</p>)
            }
          </div>
          <H1 title="行业痛点" />
          <p className="c4e fs18 mt20 lh50 mb60 m-p m-pain">{pain}</p>
          <H1 title="解决方案" />
          <p className="c4e fs18 mt20 lh50 m-p m-method">{method}</p>
        </div>
      </div>
    )
  }
}

export default DetailContent;