import React from 'react';
import './index.scss';

function NewsItem() {
	return (
		<div className="pr news-more-item">
			<p className="fs24 c4e lh33 fwb pt25 mb10 m-news-title">
				除了销售还有什么工作入行比较容易呢
			</p>
			<p className="fs20 c4e lh40 t-clamp2 m-news-main">
				销售可以说是现如今生活中赚钱比较多的一个行业，但是相应的作为一个销售，如果你想赚钱就得付出比别人更多的艰辛。那么除了销售还有什么工作入行比较容易，同时有比较轻松呢？请听小编一一为你道来。
				产品代理类
				随着社会的发展进步了销售还有什么工作入行比较容易，同时有比较轻松呢？请听小编一一为你道来。
				产品代理类 随着社会的发展进步
			</p>
			<p className="c9 fs20 lh28 mt10 pb25 bbdash-dd m-news-time">
				金闪闪新闻 | 2019年08月20日
			</p>
			<a
				target="_blank"
				href="/newsDetail"
				className="fs20 detail-btn tac cp pa"
			>
				阅读全文
			</a>
		</div>
	);
}
export default NewsItem;
