import React, { PureComponent } from 'react';
import "./index.scss";
import SubmitDialog from "../SubmitDialog";
import Select from "../Select";
import Input from "../Input";
import SubmitSuccess from "../SubmitSuccess";
import { Carousel } from 'uxcore';
import classnames from "classnames";

const Option = Select.Option;

function SubmitDialogBanner (params) {
  const { visible, cancel } = params;
  return (
    <SubmitDialog
      visible={visible}
      title={<p className="flex ac"><img width="26" className="mr10" src="https://imagesize.hrwork.com/sp/files/ca2a46f1-1765-4189-b379-ceae862cbf12.png" alt="" /><span>快联系我</span></p>}
      tip="快速提交您的信息，我们会尽快与您联系"
      m_title="快联系我"
      onCancel={cancel}
      onConfirm={cancel}
    >
      <div className="bf pt30 pl20 pr20 m-submit-dialog-box">
        <div className="flex jsb mb20">
          <Select placeholder="省份" width={220} height={40}>
            <Option value="北京">北京</Option>
            <Option value="浙江">浙江</Option>
          </Select>
          <Select placeholder="城市" width={220} height={40}>
            <Option value="杭州">杭州</Option>
          </Select>
        </div>
        <div className="mt20">
          <Input placeholder="企业名称或联系人姓名" />
        </div>
        <div className="mt20">
          <Input placeholder="联系人手机号码" />
        </div>
        <div className="mt20 flex">
          <div className="flex-1"><Input placeholder="手机短信验证码" /></div>
          <div className="code-btn cf cp fs14 tac lh40 ml20">获取验证码</div>
        </div>
        <div className="mt20">
          <Select placeholder="企业人员规模" width={460} height={40}>
            <Option value="少于100人">少于100人</Option>
            <Option value="100-500人">100-500人</Option>
            <Option value="501-1000人">501-1000人</Option>
            <Option value="1000-9999人">1000-9999人</Option>
            <Option value="10000人以上">10000人以上</Option>
          </Select>
        </div>
        {/* <div className="flex jsb mt20">
          <Select placeholder="所属行业（非必填）" width={220} height={40}>
          </Select>
          <Select placeholder="行业分类（非必填）" width={220} height={40}>
          </Select>
        </div> */}
      </div>
    </SubmitDialog>
  )
}

function SubmitSuccessBanner (params) {
  return (
    <SubmitSuccess visible={false}>
      <div className="fs28 fwb c4e tac">提交成功</div>
      <p className="c9 fs16 mt10 tac">您的信息已经提交成功，我们会尽快与您联系</p>
      <div style={{ width: "20px", height: "40px", borderRadius: "20px" }} className="submit-confirm-btn cf fs14 tac lh40 bml mxauto mt20 cp">我知道啦</div>
    </SubmitSuccess>
  )
}


class Banner extends PureComponent {

  hideSubmitDialog = () => {
    this.setState({
      isShowSubmitDialog: false
    })
  }

  state = {
    isShowSubmitDialog: false
  }

  showSubmitDialog = () => {
    this.setState({
      isShowSubmitDialog: true
    })
  }

  render () {
    const { items, home } = this.props;
    const { isShowSubmitDialog } = this.state;

    return (
      <div className={classnames("new-banner-box", {
        home: !!home
      })}>
        <div className="pc-new-banner-box">
          <Carousel arrows={false} pauseOnHover largeArrowsAndDots autoplay>
            {
              items.map(({ title, image, tips = [], showBtn = false, key }) => <div key={key} className="swiper-item-box flex ac" style={{ backgroundImage: `url("${image}")` }}>
                <div className="w1200 mxauto">
                  <p className="cf fs40 fwb">{title}</p>
                  {
                    tips.map((tip) => <p key={tip} className="cf fs20 mt10">{tip}</p>)
                  }
                  {showBtn && <div onClick={this.showSubmitDialog} className="cp btn tac cf fs20 fwb flex ac jc mt30">服务申请</div>}
                </div>
              </div>)
            }
          </Carousel>
        </div>

        <div className="m-new-banner-box hide">
          <Carousel arrows={false} pauseOnHover largeArrowsAndDots autoplay>
            {
              items.map(({ title, m_image, tips = [], showBtn = false, key }) => <div key={key} className="swiper-item-box flex ac m-pl30 jsb m-pr40" style={{ backgroundImage: `url("${m_image}")` }}>
                <div>
                  <p className="cf m-fs30 fwb">{title}</p>
                  {
                    tips.map((tip) => <p key={tip} className="cf m-fs18 m-mt10">{tip}</p>)
                  }
                </div>
                {showBtn && <div onClick={this.showSubmitDialog} className="btn tac cf m-fs20 fwb flex ac jc">服务申请</div>}

              </div>)
            }
          </Carousel>
        </div>

        <SubmitDialogBanner visible={isShowSubmitDialog} cancel={this.hideSubmitDialog} />
      </div>
    );
  }
}

export default Banner;