import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";

const Item = props => <div className="item br6 bs33 pr">
  <div style={{ backgroundColor: props.iconBgColor }} className="icon-box pa flex jc ac"><img width="56" src={props.icon} alt="" /></div>
  <p className="c4e fs30 fwb lh42 title">{props.title}</p>
  <p className="c9 fs20 lh40 mt40">{props.tip}</p>
</div>

class Youshi extends PureComponent {
  render () {
    return (
      <div className="lg-youshi-box pt1">
        <Title type="lingGong-youshi" titles={["我们的优势"]} />
        <div className="flex w1200 jsb mxauto mt40 m-lg-youshi-item">
          <Item title="招聘能力强" iconBgColor="#F98585" icon="https://imagesize.hrwork.com/sp/files/37a8c37b-8797-4ed7-bb01-b98df0309e24.png" tip="拥有全国灵活用工服务行业自助招聘平台，满足企业大批量招聘需求，快速组件团队" />
          <Item title="全国性服务能力" iconBgColor="#59D1AF" icon="https://imagesize.hrwork.com/sp/files/3bce192d-9df7-471f-b386-cc7a686d183f.png" tip="在华北、华东、华中、华南、华西开设五大区域中心，60+分公司，服务覆盖全国600+城市，及响应客户多网点用人需求" />
        </div>
        <div className="flex w1200 jsb mxauto mt70 m-lg-youshi-item">
          <Item title="经营风险管理能力" iconBgColor="#6D9DFB" icon="https://imagesize.hrwork.com/sp/files/68074e49-982f-4b0e-9e47-2e5e97696899.png" tip="多维度风险把控及预防机制，专业化的用工风险与劳务纠纷处理能力；实时管理及服务百万在岗外灵工，打造共享人才池，根据客户的波峰波谷实现人才的灵活配置" />
          <Item title="智能化系统服务" iconBgColor="#FF922D" icon="https://imagesize.hrwork.com/sp/files/e2f524eb-42e9-4d85-8cf9-92eb95caa020.png" tip="通过技术平台实现系统化数据管理，流程管理及全方位的人事服务管理" />
        </div>
      </div>
    )
  }
}
export default Youshi;