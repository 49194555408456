import { observable, action } from 'mobx'

class Login {
  @observable
  isShowLoginDialog = false;

  @action
  toggleLoginDialog = (flag) => {
    this.isShowLoginDialog = flag;
  }
}

export default new Login();