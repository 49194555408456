import React, { useEffect } from 'react';
import "./index.scss";
import classnames from "classnames";

const navList = [
  {
    name: "首页",
    id: 1,
    href: "/"
  },
  {
    name: "服务产品",
    id: 2,
    href: "/server"
  },
  {
    name: "客户案例",
    id: 3,
    href: "/clientExample"
  },
  {
    name: "关于我们",
    id: 4,
    href: "/about"
  },
]


function M_Nav ({ visible, cancel }) {
  useEffect(() => {
    if(visible) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = ""
    }
  }, [visible])
  return (
    <div data-show={visible} className={classnames("m-header-nav-wrapper", {
      show: visible,
      hide: !visible
    })}>
      <div className={classnames("m-header-nav-mask", {
      })} />
      <div className="m-header-nav-body tac">
        {
          navList.map(({ name, id, href }) => <p className="cf m-fs30 m-mb60" key={id}>
            <a href={href} className={classnames("m-click cf ib fw")}>{name}</a>
          </p>)
        }
        <span className="m-click ib" onClick={cancel}>
          <img className="close-icon" src="https://imagesize.hrwork.com/sp/files/63aafcb6-febc-4a74-95c1-c39a3fa26978.png" alt="" />
        </span>
      </div>

      <div className="concat flex ac jsa">
        <div className="weixin-box">
          <img width="100%" src="https://imagesize.hrwork.com/sp/files/ab49e139-1133-4a28-b93b-ab489b333129.jpeg" alt="" />
        </div>

        <div className="right-tip">
          <div className="m-mb20">
            <img className="m-mr10" src="https://imagesize.hrwork.com/sp/files/bd6ce174-3cdd-4eb6-8932-cfc852825669.png" alt="" />
            <span className="cf m-fs18">杭州市西湖区西溪国际商务中心E幢909室</span>
          </div>
          <div className="m-mb20">
            <img className="m-mr10" src="https://imagesize.hrwork.com/sp/files/0cd389ff-4e69-480e-9a12-53c45927c38a.png" alt="" />
            <span className="cf m-fs18">400-8787-282</span>
          </div>
          <div className="">
            <img className="m-mr10" src="https://imagesize.hrwork.com/sp/files/58970b52-ad43-4027-847b-c373972092b4.png" alt="" />
            <span className="cf m-fs18">official@duomi.cn</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default M_Nav;