import React, { PureComponent } from 'react';
import "./index.scss";
import Nav from "./component/Nav";
import JobCard from "./component/JobCard";
import Pagination from '../../component/Pagination';
import Header from "../../component/Header";
import Footer from "../../component/Footer";



class Job extends PureComponent {
  
  onTabChnage = info => {

  }

  onPageChange = page => {
    console.log(page)
  }
  render () {
    const { activeTab } = this.props.match.params;
    return (
      <div className="page-job">
        <Header activeIndex="0" />
        <Nav activeTab={activeTab} onTabChnage={this.onTabChnage} />
        <div className="job-list">
          <JobCard />
          <JobCard />
          <JobCard />
          <Pagination onChange={this.onPageChange} total={250}/>
        </div>
        <Footer />
      </div>
    )
  }
}

export default Job;
