import React from 'react';
import "./index.scss";

function Bage({icon}) {
  return (
    <div className="character-bage-box bf flex ac jc">
      <img width="62" src={icon} alt="" />
    </div>
  )
}

export default Bage;
