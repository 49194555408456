import React, { PureComponent } from 'react';
import "./index.scss";
import Title from "../../../../component/Title";
import classnames from "classnames";

const Item = props => <div className={classnames("item bf bs66 br6 tac c6", {
  [props.type]: !!props.type
})}>
  <div className={classnames("icon-box ib", {
    [props.type]: !!props.type
  })} />
  <p className="fs28 fwb mt40 lh40">{props.title}</p>
  <p className="fs28 fwb lh40">{props.tip}</p>
</div>
class Price extends PureComponent {
  render () {
    return (
      <div className="lg-price-box">
        <Title type="lingGong-price" titles={["我们的价值"]} />
        <div className="flex w1200 mxauto jsb m-lg-price-main">
          <Item type="one" title="快速招聘人才" tip="抢占市场先机" />
          <Item type="two" title="优化合规用工" tip="降低用工风险" />
          <Item type="three" title="降低员工流失率" tip="提升业务连续性" />
          <Item type="fore" title="社会化灵活用工" tip="打造人才蓄水池" />
        </div>
      </div>
    )
  }
}

export default Price;